import React from "react";
import {
	Create,
	SimpleForm,
	TextInput,
	ReferenceInput,
	AutocompleteInput,
} from "react-admin";
import BaseCreate from "../inheritance/BaseCreate";
import { required } from "react-admin";
import { Permissions } from '../../permissions/permissions';

export const FeeTypeCreate = (props) => (
	<Create {...props}>
		<SimpleForm redirect="list">
			<TextInput
				label="Nome"
				source="name"
				validate={[required("Il nome è obbligatorio")]}
			/>
			<ReferenceInput
				validate={[required("campo obbligatorio")]}
				filterToQuery={(searchText) => {
					if (!searchText) return {};
					return {
						name: searchText,
					};
				}}
				label="Business Unit"
				source="businessUnitId"
				reference={Permissions.businessUnit.feature}
			>
				<AutocompleteInput optionText="name" optionValue="id" />
			</ReferenceInput>
            <ReferenceInput
				validate={[required("campo obbligatorio")]}
				filterToQuery={(searchText) => {
					if (!searchText) return {};
					return {
						name: searchText,
					};
				}}
				label="Tassa"
				source="taxId"
				reference={Permissions.tax.feature}
			>
				<AutocompleteInput optionText="name" optionValue="id" />
			</ReferenceInput>
			<BaseCreate />
		</SimpleForm>
	</Create>
);
