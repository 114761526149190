import React from 'react';
import { Show, SimpleShowLayout, TextField, ReferenceField } from 'react-admin';
import Aside from '../inheritance/BaseAside';
import { Permissions } from '../../permissions/permissions';


export const FeeTypeShow = (props) => {
    return (
    <Show aside={<Aside props={props} record={props.record}></Aside>}  {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField label="Nome" source="name" />
            <ReferenceField
				label="Business Unit"
				source="businessUnitId"
				link="show"
				reference={Permissions.businessUnit.feature}
			>
				<TextField source="name" />
			</ReferenceField>
            <ReferenceField
				label="Tassa"
				source="taxId"
				link="show"
				reference={Permissions.tax.feature}
			>
				<TextField source="name" />
			</ReferenceField>
        </SimpleShowLayout>
    </Show>
)};
