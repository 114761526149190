import React from "react";
import {
	Edit,
	SimpleForm,
	TextInput,
	ReferenceInput,
	AutocompleteInput,
} from "react-admin";
import BaseEdit from "../inheritance/BaseEdit";
import { Permissions } from "../../permissions/permissions";
import { required } from "react-admin";

export const FeeTypeEdit = (props) => (
	<Edit {...props}>
		<SimpleForm>
			<TextInput
				label="Nome"
				source="name"
				validate={[required("Il nome è obbligatorio")]}
			/>
			<ReferenceInput
				validate={[required("campo obbligatorio")]}
				filterToQuery={(searchText) => {
					if (!searchText) return {};
					return {
						name: searchText,
					};
				}}
				label="Business Unit"
				source="businessUnitId"
				reference={Permissions.businessUnit.feature}
			>
				<AutocompleteInput optionText="name" optionValue="id" />
			</ReferenceInput>
			<ReferenceInput
				validate={[required("campo obbligatorio")]}
				filterToQuery={(searchText) => {
					if (!searchText) return {};
					return {
						name: searchText,
					};
				}}
				label="Tassa"
				source="taxId"
				reference={Permissions.tax.feature}
			>
				<AutocompleteInput optionText="name" optionValue="id" />
			</ReferenceInput>
			<BaseEdit />
		</SimpleForm>
	</Edit>
);
