import React from "react";
import {
	Create,
	SimpleForm,
	TextInput,
	ReferenceInput,
	AutocompleteInput,
} from "react-admin";
import BaseCreate from "../inheritance/BaseCreate";
import { required } from "react-admin";
import { Permissions } from '../../permissions/permissions';

export const BillingGroupCreate = (props) => (
	<Create {...props}>
		<SimpleForm redirect="list">
			<ReferenceInput
				validate={[required("campo obbligatorio")]}
				filterToQuery={(searchText) => {
					if (!searchText) return {};
					return {
						name: searchText,
					};
				}}
				label="Corporate Customer"
				source="corporateCustomerId"
				reference={Permissions.corporateCustomers.feature}
			>
				<AutocompleteInput optionText="name" optionValue="id" />
			</ReferenceInput>
			<TextInput
				label="Name"
				source="name"
				helperText="Se vuoto viene calcolato automaticamente"
			/>
            <TextInput
				label="Commento"
				source="comment"
			/>
			 <TextInput
				label="Codice"
				source="code"
				helperText="Se vuoto viene calcolato automaticamente"
			/>
			<BaseCreate />
		</SimpleForm>
	</Create>
);
