import React from "react";
import {
	Create,
	SimpleForm,
	TextInput,
	useNotify,
	DateInput,
	ReferenceInput,
	AutocompleteInput,
} from "react-admin";
import BaseCreate from "../../inheritance/BaseCreate";
import { required } from "react-admin";
import { Permissions } from "../../../permissions/permissions";


export const serviceTypeChoices = [
	{ id: "Fee", name: "Fee" },
	{ id: "Catering", name: "Catering" },
	{ id: "Credito", name: "Credito" },
	{ id: "Caffe", name: "Caffe" },
	{ id: "Frutta", name: "Frutta" },
	{ id: "Consegne dirette", name: "Consegne dirette" },
	{ id: "Catering continuativo", name: "Catering continuativo" },
	{ id: "Multipli", name: "Multipli" },
];

export function CorporateCustomerPOCreate(props) {
	const notify = useNotify();
	const urlParams = new URLSearchParams(props.location.search);
	let familyId = urlParams.get("familyId");

	if (!familyId) {
		notify("Non è stato passato l'id della famiglia", "error");
		return null;
	} else {
		familyId = parseInt(familyId);
	}

	return (
		<Create {...props}>
			<SimpleForm redirect="show">
				<ReferenceInput
					fullWidth
					validate={[required("campo obbligatorio")]}
					filterToQuery={(searchText) => {
						if (!searchText) return {};
						return {
							name: searchText,
						};
					}}
					defaultValue={familyId}
					label="Famiglia di PO"
					source="corporateCustomerPOFamilyId"
					reference={Permissions.corporateCustomerPOFamily.feature}
				>
					<AutocompleteInput
						optionText="name"
						optionValue="id"
						disabled
					/>
				</ReferenceInput>
				<TextInput fullWidth label="PO Number" source="poNumber" />
				<DateInput
					fullWidth
					label="Inizio"
					source="startDate"
					validate={[required("campo obbligatorio")]}
				/>
				<DateInput fullWidth label="Fine" source="endDate" />
				<TextInput fullWidth label="Commento" source="comment" />
				<TextInput
					fullWidth
					label="File Name"
					source="fileName"
					helperText="Se vuoto viene calcolato automaticamente"
				/>
				<BaseCreate />
			</SimpleForm>
		</Create>
	);
}
