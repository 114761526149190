import React, { useEffect, useState } from "react";
import {
	Create,
	SimpleForm,
	ReferenceInput,
	AutocompleteInput,
	NumberInput,
	DateInput,
	SelectInput,
	useDataProvider,
	useNotify,
} from "react-admin";
import BaseCreate from "../../inheritance/BaseCreate";
import { required } from "react-admin";
import { Permissions } from "../../../permissions/permissions";
import { GET_OFF_RESOURCE } from "../../../providers/nestjs_crud";

export const ContractFeeCreate = (props) => {
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const [status, setStatus] = useState("INITIAL");
	const [corporateCustomer, setCorporateCustomer] = useState();

	const urlParams = new URLSearchParams(props.location.search);
	let contractId = urlParams.get("contractId");

	useEffect(() => {
		if (contractId && status === "INITIAL") {
			setStatus("FETCHING");
			dataProvider(
				GET_OFF_RESOURCE,
				`${Permissions.contract.feature}/${contractId}/?join=corporateCustomer`
			)
				.then((response) => {
					setStatus("FETCHED");

					if (!response.data.corporateCustomer.b2bClientId) {
						notify(
							"Il Corporate Customer non ha un B2B Client",
							"error"
						);
						setStatus("ERROR_FETCH");
					}

					setCorporateCustomer(response.data.corporateCustomer);
				})
				.catch((message) => {
					setStatus("ERROR_FETCH");
					notify("Errore durante il caricamento", "error");
					console.warn(message);
				});
		}
	}, [contractId, dataProvider, notify, status]);

	if (!contractId || !corporateCustomer) {
		return <></>;
	}

	contractId = parseInt(contractId);

	if (status === "ERROR_FETCH") {
		return <></>;
	}

	return (
		<Create {...props}>
			<SimpleForm redirect="show">
				<ReferenceInput
					fullWidth
					validate={[required("campo obbligatorio")]}
					filterToQuery={(searchText) => {
						if (!searchText) return {};
						return {
							name: searchText,
						};
					}}
					defaultValue={contractId}
					label="Contratto"
					source="contractId"
					reference={Permissions.contract.feature}
				>
					<AutocompleteInput
						optionText="name"
						optionValue="id"
						disabled
					/>
				</ReferenceInput>
				<ReferenceInput
					fullWidth
					validate={[required("campo obbligatorio")]}
					filterToQuery={(searchText) => {
						if (!searchText) return {};
						return {
							name: searchText,
						};
					}}
					filter={
						corporateCustomer && corporateCustomer.b2bClientId
							? {
									place: {
										b2bClientId:
											corporateCustomer.b2bClientId,
									},
							  }
							: {
									active: 1,
							  }
					}
					label="Selling Point"
					source="sellingPointId"
					reference={Permissions.sellingPoint.feature}
				>
					<AutocompleteInput
						optionText="name"
						optionValue="id"
						helperText="Se vuoto: controlla che i luoghi abbiano il B2B Client collegato"
					/>
				</ReferenceInput>
				<ReferenceInput
					fullWidth
					filterToQuery={(searchText) => {
						if (!searchText) return {};
						return {
							name: searchText,
						};
					}}
					filter={
						corporateCustomer && corporateCustomer.id
							? {
									corporateCustomerId: corporateCustomer.id,
							  }
							: {}
					}
					allowEmpty
					label="Famiglia PO"
					source="corporateCustomerPOFamilyId"
					reference={Permissions.corporateCustomerPOFamily.feature}
				>
					<AutocompleteInput optionText="name" optionValue="id" />
				</ReferenceInput>
				<ReferenceInput
					fullWidth
					validate={[required("campo obbligatorio")]}
					filterToQuery={(searchText) => {
						if (!searchText) return {};
						return {
							name: searchText,
						};
					}}
					label="Tipologia di servizio"
					source="feeTypeId"
					reference={Permissions.feeType.feature}
				>
					<AutocompleteInput optionText="name" optionValue="id" />
				</ReferenceInput>
				<NumberInput
					fullWidth
					label="Importo"
					source="amount"
					validate={[required("campo obbligatorio")]}
				/>
				<DateInput
					fullWidth
					label="Data di inizio fee"
					source="startDate"
					validate={[required("campo obbligatorio")]}
				/>
				<DateInput
					fullWidth
					label="Data di fine"
					source="endDate"
					validate={[required("campo obbligatorio")]}
				/>
				<DateInput
					fullWidth
					label="Data di Inizio fatturazione"
					source="billingStartDate"
					validate={[required("campo obbligatorio")]}
				/>
				<DateInput
					fullWidth
					label="Data di fine periodo minimo"
					source="earlyTerminationAllowedDate"
				/>
				<SelectInput
					fullWidth
					label="Strategia P&L"
					source="pnlStrategy"
					validate={[required("campo obbligatorio")]}
					defaultValue={"Included"}
					choices={[
						{
							id: "Included",
							name: "Includi",
						},
						{
							id: "Excluded",
							name: "Escludi",
						},
					]}
				/>
				<NumberInput
					fullWidth
					label="Visite Minime"
					source="minVisits"
					min={0}
					validate={[required("campo obbligatorio")]}
				/>
				<NumberInput
					fullWidth
					label="Visite Massime"
					source="maxVisits"
					min={0}
					validate={[required("campo obbligatorio")]}
				/>
				<BaseCreate />
			</SimpleForm>
		</Create>
	);
};
