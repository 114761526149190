import React, { useEffect, useState } from "react";
import {
	Create,
	SimpleForm,
	TextInput,
	ReferenceInput,
	AutocompleteInput,
	NumberInput,
	SelectInput,
	DateInput,
} from "react-admin";
import BaseCreate from "../inheritance/BaseCreate";
import { required } from "react-admin";
import { Permissions } from "../../permissions/permissions";

export const ContractCreate = (props) => {
	const [corporateCustomerId, setCorporateCustomerId] = useState(undefined);
	const urlParams = new URLSearchParams(props.location.search);
	let corporateCustomerIdParam = urlParams.get("corporateCustomerId");

	useEffect(() => {
		if (corporateCustomerIdParam) {
			setCorporateCustomerId(corporateCustomerIdParam);
		}
	}, [corporateCustomerIdParam]);

	return (
		<Create {...props}>
			<SimpleForm redirect="list">
				<TextInput
					fullWidth
					label="Name"
					source="name"
					helperText="Se vuoto viene calcolato automaticamente"
				/>
				<ReferenceInput
					fullWidth
					validate={[required("campo obbligatorio")]}
					filterToQuery={(searchText) => {
						if (!searchText) return {};
						return {
							name: searchText,
						};
					}}
					label="Corporate Customer"
					source="corporateCustomerId"
					reference={Permissions.corporateCustomers.feature}
					defaultValue={corporateCustomerIdParam}
					disabled={!!corporateCustomerIdParam}
				>
					<AutocompleteInput
						optionText="name"
						optionValue="id"
						onSelect={(corporateCustomer) =>
							setCorporateCustomerId(corporateCustomer.id)
						}
					/>
				</ReferenceInput>
				<ReferenceInput
					fullWidth
					validate={[required("campo obbligatorio")]}
					filterToQuery={(searchText) => {
						if (!searchText) return {};
						return {
							name: searchText,
						};
					}}
					label="Gruppo di Fatturazione"
					source="billingGroupId"
					reference={Permissions.billingGroup.feature}
					filter={
						corporateCustomerId
							? {
									corporateCustomerId: corporateCustomerId,
							  }
							: {}
					}
				>
					<AutocompleteInput optionText="name" optionValue="id" />
				</ReferenceInput>
				<NumberInput
					fullWidth
					label="Frequenza ( mesi)"
					source="billingFrequency"
					validate={[required("campo obbligatorio")]}
					defaultValue={1}
				/>
				<SelectInput
					fullWidth
					label="Giorni di pagamento ( ggfm )"
					source="paymentNetDays"
					validate={[required("campo obbligatorio")]}
					defaultValue={30}
					choices={[
						{
							id: 0,
							name: "0",
						},
						{
							id: 30,
							name: "30",
						},
						{
							id: 60,
							name: "60",
						},
						{
							id: 90,
							name: "90",
						},
					]}
				/>
				<DateInput
					fullWidth
					label="Data di firma"
					source="signedAt"
					validate={[required("campo obbligatorio")]}
				/>
				<DateInput
					fullWidth
					label="Data di inizio contratto"
					source="startDate"
					validate={[required("campo obbligatorio")]}
				/>
				<DateInput
					fullWidth
					label="Data di fine pre rinnovo"
					source="preRenewalEndDate"
					validate={[required("campo obbligatorio")]}
				/>
				<DateInput
					fullWidth
					label="Data di fine post rinnovo"
					source="postRenewalEndDate"
					validate={[required("campo obbligatorio")]}
				/>
				<DateInput
					fullWidth
					label="Data di fine periodo di prova"
					source="endTrialDate"
				/>
				<SelectInput
					fullWidth
					label="Metodo di Pagamento"
					source="paymentProvider"
					validate={[required("campo obbligatorio")]}
					choices={[
						{
							id: "SEPA",
							name: "SEPA",
						},
						{
							id: "Bonifico",
							name: "Bonifico",
						},
						{
							id: "PayPal",
							name: "PayPal",
						},
						{
							id: "Compensazione",
							name: "Compensazione",
						},
					]}
				/>
				<TextInput
					fullWidth
					label="Informazioni di pagamento"
					source="paymentProviderData"
				/>
				<TextInput
					fullWidth
					label="Note interne"
					source="internalComment"
				/>
				<TextInput
					fullWidth
					label="Note per cliente ( visibili in fatturazione )"
					source="externalComment"
				/>
				<BaseCreate />
			</SimpleForm>
		</Create>
	);
};
