import React from "react";
import {
	Show,
	SimpleShowLayout,
	TextField,
	ReferenceField,
	NumberField,
	DateField,
} from "react-admin";
import { Permissions } from "../../../permissions/permissions";
import BaseAsideWithUser from "../../inheritance/BaseAsideWithUser";

export const ContractFeeShow = (props) => {
	return (
		<Show
			aside={
				<BaseAsideWithUser
					props={props}
					record={props.record}
					hasHistory
				></BaseAsideWithUser>
			}
			{...props}
		>
			<SimpleShowLayout>
				<ReferenceField
					label="Contratto"
					source="contractId"
					link="show"
					reference={Permissions.contract.feature}
				>
					<TextField source="name" />
				</ReferenceField>
				<ReferenceField
					label="Punti di Vendita ( per P&L )"
					source="sellingPointId"
					link="show"
					reference={Permissions.sellingPoint.feature}
				>
					<TextField source="name" />
				</ReferenceField>
				<ReferenceField
					label="Famiglia PO"
					source="corporateCustomerPOFamilyId"
					reference={Permissions.corporateCustomerPOFamily.feature}
					link="show"
				>
					<TextField source="name" />
				</ReferenceField>
				<ReferenceField
					label="Tipologia di servizio"
					source="feeTypeId"
					reference={Permissions.feeType.feature}
					link="show"
				>
					<TextField source="name" />
				</ReferenceField>
				<NumberField
					label="Importo"
					source="amount"
					options={{ style: "currency", currency: "EUR" }}
				/>
				<DateField label="Data di inizio fee" source="startDate" />
				<DateField label="Data di fine" source="endDate" />
				<DateField
					label="Data di inizio fatturazione"
					source="billingStartDate"
				/>
				<DateField
					label="Data di fine periodo minimo"
					source="earlyTerminationAllowedDate"
				/>
				<TextField label="Strategia P&L" source="pnlStrategy" />
				<NumberField label="Visite Minime" source="minVisits" />
				<NumberField label="Visite Massime" source="maxVisits" />
			</SimpleShowLayout>
		</Show>
	);
};
