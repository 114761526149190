import React, { useEffect, useState } from 'react';
import { SimpleShowLayout, TextField, TopToolbar, ReferenceField, ShowController, ShowView, Loading, ReferenceArrayField, SingleFieldList, ChipField, useDelete, showNotification } from 'react-admin';
import Aside from '../../../../inheritance/BaseAsideWithUser';
import { Permissions } from "../../../../../../permissions/permissions";
import {Button } from '@material-ui/core';
import {Edit as EditIcon} from '@material-ui/icons';
import { useHistory } from "react-router-dom";
import { NoApiCallReferenceField } from '../../../../../../components/NoApiCallReferenceField';
import { Grid, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TransferItemsField } from '../../../../../../components/TransferItemsField';
import { useDispatch } from 'react-redux';
import { Delete as DeleteIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding: theme.spacing(1)
    }
}));

function ShowActions(props) {
    const history = useHistory();

	return (
		<TopToolbar>
			<Button color="primary" variant="text" startIcon={<EditIcon color="primary" />} onClick={() => {
                history.push(`/mission-recurring/job/${props.data.id}/edit`);
            }}>
                Edit
            </Button>
            {props.resource 
                && props.permissions 
                && props.permissions.includes(`${props.resource}-${Permissions[props.resource].actions.DeleteOneJob}`) 
                && <DeleteWithConfirmation {...props} />
            }
		</TopToolbar>
	);
}

export function RecurringJobShow(props) {
    const classes = useStyles();

    return (
        <ShowController {...props}>
            {controllerProps => !controllerProps.loaded ? <Loading /> :
                <Container maxWidth="xl" className={classes.root}>
                    <Grid container spacing={3}>
                        <Grid item xl={12} lg={12} md={12} xs={12}>
                            <ShowView {...props} {...controllerProps} 
                                aside={<Aside props={props} 
                                record={props.record}></Aside>} 
                                actions={<ShowActions {...props}/>}
                            >
                            <SimpleShowLayout>
                                <NoApiCallReferenceField
                                    data={controllerProps.record}
                                    label="Recurring Mission"
                                    reference={Permissions.recurringMission.feature}
                                    referenceIdentifier="recurringMissionId"
                                    source="recurringMissionId" />
                                <TextField label="Type" source="type" />
                                <br />
                                <ReferenceField
                                    sortable={false}
                                    label="Selling point"
                                    reference={`${Permissions.sellingPoint.feature}`}
                                    source="sellingPointId"
                                    link="show"
                                >
                                    <TextField source="name" />
                                </ReferenceField>
                                <ReferenceField
                                    sortable={false}
                                    label="Warehouse Location"
                                    reference={`${Permissions.warehouseLocation.feature}`}
                                    source="warehouseLocationId"
                                    link="show"
                                >
                                    <TextField source="name" />
                                </ReferenceField>
                                {controllerProps.record.durationMinutes !== null && <TextField label="Duration (Minutes)" source="durationMinutes" />}
                                {controllerProps.record.businessUnitId !== null && <ReferenceField link='show' label={"Business Unit ID"} source="businessUnitId" reference={Permissions.businessUnit.feature}>
                                    <TextField source="name" />
                                </ReferenceField>}
                                {controllerProps.record.deliveryManSkills !== null && Array.isArray(controllerProps.record.deliveryManSkills) && controllerProps.record.deliveryManSkills.length > 0 && <ReferenceArrayField label="Skills" reference={Permissions.deliveryManSkill.feature} source="deliveryManSkills">
                                    <SingleFieldList linkType="show">
                                        <ChipField source="name" />
                                    </SingleFieldList>
                                </ReferenceArrayField>}
                                {controllerProps.record.missionJobTags !== null 
                                    && Array.isArray(controllerProps.record.missionJobTags) 
                                    && controllerProps.record.missionJobTags.length > 0 
                                    && 
                                    <ReferenceArrayField label="Job Tags" reference={Permissions.missionJobTag.feature} source="missionJobTags">
                                        <SingleFieldList linkType="show">
                                            <ChipField source="name" />
                                        </SingleFieldList>
                                    </ReferenceArrayField>
                                }
                                {controllerProps.record.maxAdvanceMinutes !== null && <TextField label="Max Advance (Minutes)" source="maxAdvanceMinutes" />}
                                {controllerProps.record.inventoryExecution !== null && <TextField label="Inventory Execution" source="inventoryExecution" />}
                                {controllerProps.record.inventoryLotCount !== null && <TextField label="Inventory Lot Count" source="inventoryLotCount" />}
                                {controllerProps.record.inventoryCycleCount !== null && <TextField label="Inventory Cycle Count" source="inventoryCycleCount" />}
                                {controllerProps.record.inventoryIds !== null && Array.isArray(controllerProps.record.inventoryIds) && controllerProps.record.inventoryIds.length > 0 && <ReferenceArrayField label="Inventory IDs" reference={Permissions.warehouseInventory.feature} source="inventoryIds">
                                    <SingleFieldList linkType="show">
                                        <ChipField source="id" />
                                    </SingleFieldList>
                                </ReferenceArrayField>}
                                {controllerProps.record.loadTransferExecution !== null && <TextField label="Load Transfer Execution" source="loadTransferExecution" />}
                                {controllerProps.record.hubLoadingLocationType !== null && <TextField label="Tipologia location di carico" source="hubLoadingLocationType" />}
                                {controllerProps.record.unLoadTransferExecution !== null && <TextField label="UnLoad Transfer Execution" source="unLoadTransferExecution" />}
                                {controllerProps.record.hubUnLoadingLocationType !== null && <TextField label="Tipologia location di scarico" source="hubUnLoadingLocationType" />}
                                {controllerProps.record.executionComment !== null && <TextField label="Commento per esecuzione" source="executionComment" />}
                                {controllerProps.record.loadTransferComment !== null && <TextField label="Commento per Carico" source="loadTransferComment" />}
                                {controllerProps.record.unLoadTransferComment !== null && <TextField label="Commento per Scarico" source="unLoadTransferComment" />}
                            </SimpleShowLayout>
                        </ShowView>
                    </Grid>
                </Grid>
                <TransferItemsField items={controllerProps.record.transferItems}/>
            </ Container>
            } 
        </ShowController>
    );
}

function DeleteWithConfirmation(props) {
	const history = useHistory();
	const [deleteStatus, setDeleteStatus] = useState("INITIAL");
    const dispatch = useDispatch();
	const [deleteOne, { loaded, isPending, error }] = useDelete(props.resource, props.id);

	useEffect(() => {
        if(deleteStatus === 'REQUEST_DELETE'){
            setDeleteStatus('DELETING');
            deleteOne();
        }

        if(deleteStatus === 'DELETING'){
            if(loaded && !error){
                history.goBack();
            }
    
            if(!loaded && error){
                dispatch(showNotification(error, "error"));
                setDeleteStatus('INITIAL');
            }
        }
	}, [history, deleteStatus, deleteOne, loaded, error, dispatch]);

	return (
		<Button
            disabled={isPending}
			variant="text"
            style={{ color: "red" }}
            startIcon={<DeleteIcon />}
			onClick={() => {
				var confirm = window.confirm(
					"Procedere con la cancellazione?"
				);
				if (confirm) {
					setDeleteStatus("REQUEST_DELETE");
				} else {
					setDeleteStatus("INITIAL");
				}
			}}
		>
			DELETE
		</Button>
	);
}