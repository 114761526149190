import React from "react";
import {
	Edit,
	SimpleForm,
	TextInput,
	ReferenceInput,
	AutocompleteInput,
	DateInput,
	SelectInput,
	NumberInput,
} from "react-admin";
import BaseEdit from "../inheritance/BaseEdit";
import { Permissions } from "../../permissions/permissions";
import { required } from "react-admin";

export const ContractEdit = (props) => {
	return (
		<Edit {...props}>
			<SimpleForm>
				<TextInput
					fullWidth
					label="Nome"
					source="name"
					validate={[required("campo obbligatorio")]}
				/>
				<ReferenceInput
					fullWidth
					validate={[required("campo obbligatorio")]}
					filterToQuery={(searchText) => {
						if (!searchText) return {};
						return {
							name: searchText,
						};
					}}
					label="Corporate Customer"
					source="corporateCustomerId"
					reference={Permissions.corporateCustomers.feature}
				>
					<AutocompleteInput
						optionText="name"
						optionValue="id"
						disabled
					/>
				</ReferenceInput>
				<FilteredBillingGroupInput />
				<NumberInput
					fullWidth
					label="Frequenza ( mesi)"
					source="billingFrequency"
					validate={[required("campo obbligatorio")]}
					defaultValue={1}
				/>
				<SelectInput
					fullWidth
					label="Giorni di pagamento ( ggfm )"
					source="paymentNetDays"
					validate={[required("campo obbligatorio")]}
					defaultValue={30}
					choices={[
						{
							id: 0,
							name: "0",
						},
						{
							id: 30,
							name: "30",
						},
						{
							id: 60,
							name: "60",
						},
						{
							id: 90,
							name: "90",
						},
					]}
				/>
				<DateInput
					fullWidth
					label="Data di firma"
					source="signedAt"
					validate={[required("campo obbligatorio")]}
				/>
				<DateInput
					fullWidth
					label="Data di inizio contratto"
					source="startDate"
					validate={[required("campo obbligatorio")]}
				/>
				<DateInput
					fullWidth
					label="Data di chiusura contratto"
					source="endDate"
				/>
				<DateInput
					fullWidth
					label="Data di fine pre rinnovo"
					source="preRenewalEndDate"
					validate={[required("campo obbligatorio")]}
				/>
				<DateInput
					fullWidth
					label="Data di fine post rinnovo"
					source="postRenewalEndDate"
					validate={[required("campo obbligatorio")]}
				/>
				<DateInput
					fullWidth
					label="Data di fine periodo di prova"
					source="endTrialDate"
				/>
				<DateInput
					fullWidth
					label="Data di ricezione PEC disdetta"
					source="cancellationNoticedDate"
				/>
				<SelectInput
					fullWidth
					label="Metodo di Pagamento"
					source="paymentProvider"
					validate={[required("campo obbligatorio")]}
					choices={[
						{
							id: "SEPA",
							name: "SEPA",
						},
						{
							id: "Bonifico",
							name: "Bonifico",
						},
						{
							id: "PayPal",
							name: "PayPal",
						},
						{
							id: "Compensazione",
							name: "Compensazione",
						},
					]}
				/>
				<TextInput
					fullWidth
					label="Informazioni di pagamento"
					source="paymentProviderData"
				/>
				<TextInput
					fullWidth
					label="Note interne"
					source="internalComment"
				/>
				<TextInput
					fullWidth
					label="Note per cliente ( visibili in fatturazione )"
					source="externalComment"
				/>
				<BaseEdit />
			</SimpleForm>
		</Edit>
	);
};

const FilteredBillingGroupInput = (props) => {
	if (!props.record) return null;

	return (
		<ReferenceInput
			fullWidth
			validate={[required("campo obbligatorio")]}
			filterToQuery={(searchText) => {
				if (!searchText) return {};
				return {
					name: searchText,
				};
			}}
			label="Gruppo di Fatturazione"
			source="billingGroupId"
			reference={Permissions.billingGroup.feature}
			filter={
				props.record.corporateCustomerId
					? {
							corporateCustomerId:
								props.record.corporateCustomerId,
					  }
					: {}
			}
		>
			<AutocompleteInput optionText="name" optionValue="id" />
		</ReferenceInput>
	);
};
