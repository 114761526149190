import React from "react";
import {
	Show,
	SimpleShowLayout,
	TextField,
	ReferenceField,
	DateField,
	useNotify,
} from "react-admin";
import Aside from "../../inheritance/BaseAside";
import { Permissions } from "../../../permissions/permissions";
import { Button } from "@material-ui/core";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

export const CorporateCustomerPOShow = (props) => {
	return (
		<Show
			aside={<Aside props={props} record={props.record}></Aside>}
			{...props}
		>
			<SimpleShowLayout>
				<TextField label="ID" source="id" />
				<ReferenceField
					label="Famiglia di PO"
					source="corporateCustomerPOFamilyId"
					link="show"
					reference={Permissions.corporateCustomerPOFamily.feature}
				>
					<TextField source="name" />
				</ReferenceField>
				<TextField label="Numero PO" source="poNumber" />
				<DateField label="Inizio" source="startDate" />
				<DateField label="Fine" source="endDate" />
				<TextField label="Commento" source="comment" />
				<TextField label="Nome del File" source="fileName" />
				<CopyButton field="fileName" label="Copia nome del file" />
			</SimpleShowLayout>
		</Show>
	);
};

const CopyButton = (props) => {
	const notify = useNotify();

	const copyToClipboard = () => {
		if (!props.record || !props.record[props.field]) {
			notify(`Non è possibile copiare ${props.field} negli appunti`);
			return;
		}

		navigator.clipboard.writeText(props.record[props.field]);
		notify(`${props.field} copiato negli appunti`);
	};

	return (
		<Button
			color="primary"
			startIcon={<FileCopyOutlinedIcon />}
			size="small"
			onClick={copyToClipboard}
		>
			{props.label || "Copia"}
		</Button>
	);
};
