import React from "react";
import { Button, usePermissions, useRedirect } from "react-admin";
import HistoryIcon from "@material-ui/icons/History";
import { Permissions } from "../permissions/permissions";

function HistoryButton(props) {
	const redirect = useRedirect();
	const { permissions } = usePermissions();

	if (
		!props.record ||
		!permissions ||
		!permissions.includes(
			`${Permissions.history.feature}-${
				Permissions.history.actions.ReadAll
			}`
		)
	) {
		return <></>;
	}

	const { record } = props;

	const handleClick = () => {
		redirect(
			`/history?filter={"ownerId||eq":${record.id},"type||eq":"${props.resource}"}`
		);
	};

	return (
		<Button
			variant="contained"
			label="History"
			onClick={handleClick}
			sx={{ margin: "10px" }}
		>
			<HistoryIcon />
		</Button>
	);
}

export default HistoryButton;
