import React from "react";
import {
	Datagrid,
	List,
	TextField,
	TextInput,
	NumberInput,
	ReferenceField,
	DateField,
    ShowButton,
} from "react-admin";
import Filter from "../inheritance/BaseFilter";
import ListActions from "../../components/ListActions";
import { Permissions } from "../../permissions/permissions";
import { OwnerField } from "./HistoryShow";

const HistoryFilter = (props) => (
	<Filter {...props}>
		<TextInput label="Type" source="type||eq" alwaysOn disabled/>
		<TextInput label="Owner ID" source="ownerId||eq" alwaysOn disabled/>
		<NumberInput label="Author ID" source="creation_user" />
	</Filter>
);

export const HistoryList = (props) => (
	<List
		{...props}
		actions={<ListActions />}
		filters={<HistoryFilter />}
		bulkActionButtons={false}
		sort={{ field: "created_at", order: "DESC" }}
	>
		<Datagrid
			rowClick={false}
		>
			<TextField label="Type" source="type" />
            <OwnerField label="Owner" />
			<ReferenceField
				link="show"
				label="Editor"
				source="creation_user"
				reference={Permissions.mainUser.feature}
			>
				<TextField source="label" />
			</ReferenceField>
			<DateField label="Date" source="created_at" showTime />
            <ShowButton/>
		</Datagrid>
	</List>
);
