import React from "react";
import { Show, SimpleShowLayout, TextField, ReferenceField } from "react-admin";
import Aside from "../inheritance/BaseAside";
import { Permissions } from "../../permissions/permissions";

export const BillingGroupShow = (props) => {
	return (
		<Show
			aside={<Aside props={props} record={props.record}></Aside>}
			{...props}
		>
			<SimpleShowLayout>
				<TextField label="ID" source="id" />
				<TextField label="Name" source="name" />
				<ReferenceField
					label="Corporate Customer"
					source="corporateCustomerId"
					link="show"
					reference={Permissions.corporateCustomers.feature}
				>
					<TextField source="name" />
				</ReferenceField>
				<TextField label="Codice" source="code" />
				<TextField label="Commento" source="comment" />
			</SimpleShowLayout>
		</Show>
	);
};
