import { CorporateCustomerPOEdit } from "./CorporateCustomerPOEdit";
import  {CorporateCustomerPOCreate}  from "./CorporateCustomerPOCreate";
import { CorporateCustomerPOShow } from "./CorporateCustomerPOShow";
import CorporateCustomerPOList from "./CorporateCustomerPOList";

export default {
	edit: CorporateCustomerPOEdit,
	list: CorporateCustomerPOList,
	create: CorporateCustomerPOCreate,
	show: CorporateCustomerPOShow,
};
