import React from "react";
import { Show, SimpleShowLayout, TextField, ReferenceField, UrlField } from "react-admin";
import Aside from "../../inheritance/BaseAside";
import { Permissions } from "../../../permissions/permissions";
import { Grid, Container } from "@material-ui/core";
import CorporateCustomerPOList from "../Individual/CorporateCustomerPOList";

export const CorporateCustomerPOFamilyShow = (props) => {
	return (
		<Container maxWidth="xl">
			<Grid container spacing={3}>
				<Grid item xl={12} lg={12} md={12} xs={12}>
					<Show
						aside={
							<Aside props={props} record={props.record}></Aside>
						}
						{...props}
					>
						<SimpleShowLayout>
							<TextField label="ID" source="id" />
							<TextField label="Name" source="name" />
							<ReferenceField
								label="Corporate Customer"
								source="corporateCustomerId"
								link="show"
								reference={
									Permissions.corporateCustomers.feature
								}
							>
								<TextField source="name" />
							</ReferenceField>
							<TextField
								label="Sotto Categoria"
								source="subCategory"
							/>
							<TextField label="Servizio" source="serviceType" />
							<UrlField label="Link" source="link" />
						</SimpleShowLayout>
					</Show>
				</Grid>
				<Grid container spacing={3}>
					<Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
						<CorporateCustomerPOList familyId={props.id} />
					</Grid>
				</Grid>
			</Grid>
		</Container>
	);
};
