import React from "react";
import {
	Edit,
	SimpleForm,
	TextInput,
	ReferenceInput,
	AutocompleteInput,
	SelectInput,
} from "react-admin";
import BaseEdit from "../../inheritance/BaseEdit";
import { Permissions } from "../../../permissions/permissions";
import { required } from "react-admin";
import { serviceTypeChoices } from "./CorporateCustomerPOFamilyCreate";

export const CorporateCustomerPOFamilyEdit = (props) => (
	<Edit {...props}>
		<SimpleForm>
			<ReferenceInput
				validate={[required("campo obbligatorio")]}
				filterToQuery={(searchText) => {
					if (!searchText) return {};
					return {
						name: searchText,
					};
				}}
				label="Corporate Customer"
				source="corporateCustomerId"
				reference={Permissions.corporateCustomers.feature}
			>
				<AutocompleteInput
					disabled
					optionText="name"
					optionValue="id"
				/>
			</ReferenceInput>
			<TextInput label="Name" source="name" validate={[required("campo obbligatorio")]}/>
			<SelectInput
				label="Servizio"
				source="serviceType"
				choices={serviceTypeChoices}
				validate={[required("campo obbligatorio")]}
			/>
			<TextInput label="Sotto Categoria" source="subCategory" />
			<TextInput label="Link" source="link" />
			<BaseEdit />
		</SimpleForm>
	</Edit>
);
