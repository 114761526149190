import React from "react";
import {
	Datagrid,
	List,
	TextField,
	TextInput,
	ReferenceField,
	ReferenceInput,
	AutocompleteInput,
	NumberInput,
	AutocompleteArrayInput,
} from "react-admin";
import Filter from "../../inheritance/BaseFilter";
import ListActions from "../../../components/ListActions";
import { Permissions } from "../../../permissions/permissions";
import { serviceTypeChoices } from "./CorporateCustomerPOFamilyCreate";

const CorporateCustomerPOFamilyFilter = (props) => (
	<Filter {...props}>
		<NumberInput label="ID" source="id||eq" alwaysOn />
		<TextInput label="Sotto categoria" source="subCategory" />
		<ReferenceInput
			alwaysOn
			filterToQuery={(searchText) => {
				if (!searchText) return {};

				return {
					name: searchText,
				};
			}}
			label="Corporate Customer"
			source="corporateCustomerId||eq"
			reference={Permissions.corporateCustomers.feature}
		>
			<AutocompleteInput optionValue="id" />
		</ReferenceInput>
		<AutocompleteArrayInput
			allowEmpty={false}
			label="Servizio"
			source="serviceType||in"
			choices={serviceTypeChoices}
		/>
	</Filter>
);

export const CorporateCustomerPOFamilyList = (props) => (
	<List
		{...props}
		actions={<ListActions />}
		filters={<CorporateCustomerPOFamilyFilter />}
	>
		<Datagrid rowClick="show">
			<TextField label="ID" source="id" />
			<TextField label="Name" source="name" />
			<ReferenceField
				label="Corporate Customer"
				source="corporateCustomerId"
				link="show"
				reference={Permissions.corporateCustomers.feature}
			>
				<TextField source="name" />
			</ReferenceField>
			<TextField label="Sotto categoria" source="subCategory" />
			<TextField label="Servizio" source="serviceType" />
		</Datagrid>
	</List>
);
