import React from "react";
import {
	Datagrid,
	List,
	TextField,
	NumberInput,
	TopToolbar,
	DateField,
	CreateButton,
	DateInput,
	BooleanInput,
	FilterButton,
	ReferenceField,
	ExportButton,
	ReferenceInput,
	AutocompleteInput,
} from "react-admin";
import { Permissions } from "../../../permissions/permissions";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";

const ListActions = (props) => {
	const history = useHistory();

	return (
		<TopToolbar>
			<FilterButton />
			{props.familyId && (
				<CreateButton
					onClick={(e) => {
						e.preventDefault();
						history.push(
							`/${Permissions.corporateCustomerPO.feature}/create?familyId=${props.familyId}`
						);
					}}
				/>
			)}
			{!props.familyId && <ExportButton />}
		</TopToolbar>
	);
};

export default function CorporateCustomerPOList(props) {
	const filters = [
		<NumberInput label="ID" source="id||eq" />,
		<BooleanInput
			label="Non scaduti"
			source="endDate||isnull"
			defaultValue={true}
			onPasteCapture={() => true}
			disabled
		/>,
		<BooleanInput
			label="Scaduti"
			source="endDate||notnull"
			defaultValue={true}
			onPasteCapture={() => true}
			disabled
		/>,
		<ReferenceInput
			alwaysOn
			filterToQuery={(searchText) => {
				if (!searchText) return {};

				return {
					name: searchText,
				};
			}}
			label="PO family"
			source="corporateCustomerPOFamilyId||eq"
			reference={Permissions.corporateCustomerPOFamily.feature}
			disabled={props.familyId !== undefined}
		>
			<AutocompleteInput optionValue="id" />
		</ReferenceInput>,
		<DateInput source={`startDate||gte`} label="Inizio da" />,
		<DateInput source={`endDate||lte`} label="Fine al" />,
		<BooleanInput
			label="Solo Attivi"
			source="active"
			defaultValue={true}
		/>,
	];

	return (
		<>
			{props.familyId && (
				<Typography
					variant="h5"
					color="primary"
					style={{ padding: "10px" }}
				>
					PO
				</Typography>
			)}
			<List
				title={"PO"}
				actions={<ListActions familyId={props.familyId} />}
				basePath={`/${Permissions.corporateCustomerPO.feature}`}
				resource={`${Permissions.corporateCustomerPO.feature}`}
				filters={filters}
				filterDefaultValues={
					props.familyId
						? {
								"corporateCustomerPOFamilyId||eq":
									parseInt(props.familyId),
						  }
						: {}
				}
				bulkActionButtons={false}
			>
				<Datagrid rowClick="show">
					<TextField label="ID" source="id" />
					{!props.familyId && (
						<ReferenceField
							label="Famiglia di PO"
							source="corporateCustomerPOFamilyId"
							link="show"
							reference={
								Permissions.corporateCustomerPOFamily.feature
							}
						>
							<TextField source="name" />
						</ReferenceField>
					)}
					<DateField label="Inizio" source="startDate" />
					<DateField label="Fine" source="endDate" />
				</Datagrid>
			</List>
		</>
	);
}
