import React, { useEffect, useMemo, useState } from "react";
import {
	SimpleShowLayout,
	TextField,
	ShowController,
	ShowView,
	ReferenceField,
	useDataProvider,
	Link,
} from "react-admin";
import Aside from "../inheritance/BaseAside";
import { Permissions } from "../../permissions/permissions";
import { Box, Typography } from "@material-ui/core";
import { GET_OFF_RESOURCE } from "../../providers/nestjs_crud";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

export const HistoryShow = (props) => {
	return (
		<ShowController {...props}>
			{(controllerProps) => (
				<ShowView
					{...props}
					{...controllerProps}
					aside={<Aside props={props} record={props.record}></Aside>}
				>
					<SimpleShowLayout>
						<TextField label="ID" source="id" />
						<TextField label="Type" source="type" />
						<OwnerField {...props} show />
						<ReferenceField
							link="show"
							label="Editor"
							source="creation_user"
							reference={Permissions.mainUser.feature}
						>
							<TextField source="label" />
						</ReferenceField>
						<HistoryDataShow {...props} />
					</SimpleShowLayout>
				</ShowView>
			)}
		</ShowController>
	);
};

function HistoryDataShow(props) {
	if (!props.record) {
		return <div></div>;
	}

	const { record } = props;
	const data = JSON.parse(record.data);
	return (
		<Box
			display="flex"
			flexDirection="column"
			sx={{
				border: "2px solid #086d40",
				borderRadius: "4px",
				padding: " 10px",
				gap: "5px",
			}}
		>
			<Typography
				variant="h6"
				color="primary"
				style={{
					borderBottom: "1px solid #086d40",
				}}
			>
				Informazioni salvate
			</Typography>
			{Object.keys(data)
				.filter(
					(key) => data[key] === null || typeof data[key] !== "object"
				)
				.map((key) => {
					return (
						<Box key={key} mb={1}>
							<Typography variant="caption">{key}</Typography>
							{typeof data[key] === "boolean" && (
								<Typography>
									{data[key] === true ? (
										<CheckIcon />
									) : (
										<CloseIcon />
									)}
								</Typography>
							)}
							{["string", "number"].includes(
								typeof data[key]
							) && <Typography>{data[key]}</Typography>}
						</Box>
					);
				})}
		</Box>
	);
}

export const OwnerField = (props) => {
	const ownerTypeConfigs = {
		Contract: {
			permission: Permissions.contract.feature,
			fieldToShow: "name",
		},
		ContractFee: {
			permission: Permissions.contractFee.feature,
			fieldToShow: "id",
		},
	};
	const [status, setStatus] = useState("INITIAL");
	const dataProvider = useDataProvider();
	const [ownerValue, setOwnerValue] = useState("");

	const { record } = props;
	const data = record.data ? JSON.parse(record.data) : {};

	const ownerTypeConfig = useMemo(
		() => ownerTypeConfigs[record.type],
		[ownerTypeConfigs, record.type]
	);

	useEffect(() => {
		if (!props.show && status === "INITIAL") {
			setStatus("FETCHED");
			setOwnerValue(record.ownerId);
			return;
		}

		if (!record || !ownerTypeConfig || status !== "INITIAL") {
			return;
		}

		setStatus("FETCHING");

		dataProvider(
			GET_OFF_RESOURCE,
			`${ownerTypeConfig.permission}/${record.ownerId}`
		)
			.then((result) => {
				if (result.data.success === false) {
					console.log("Something went wrong");
					setOwnerValue(record.ownerId);
					setStatus("FETCHING_ERROR");
				} else {
					setOwnerValue(result.data[ownerTypeConfig.fieldToShow]);
					setStatus("FETCHED");
				}
			})
			.catch((error) => {
				console.log("Something went wrong 2");
				setOwnerValue(record.ownerId);
				setStatus("FETCHING_ERROR");
			});
	}, [data, dataProvider, ownerTypeConfig, props.show, record, status]);

	if (!record || !ownerTypeConfig) {
		return <></>;
	}

	return (
		<Box display="flex" flexDirection="column" mb={1}>
			{props.show && (
				<Typography variant="caption" sx={{ color: "#0000008a" }}>
					Owner
				</Typography>
			)}
			<Link to={`/${ownerTypeConfig.permission}/${record.ownerId}/show`}>
				{ownerValue}
			</Link>
		</Box>
	);
};

export default HistoryShow;
