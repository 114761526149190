import React from "react";
import {
	Datagrid,
	List,
	TextField,
	NumberInput,
	ReferenceField,
	DateField,
	NumberField,
	FilterButton,
	ExportButton,
	TopToolbar,
	CreateButton,
	BooleanInput,
	DateInput,
	ReferenceInput,
	AutocompleteInput,
} from "react-admin";
import { Typography } from "@material-ui/core";

import { Permissions } from "../../../permissions/permissions";
import { useHistory } from "react-router-dom";

const ListActions = (props) => {
	const history = useHistory();

	return (
		<TopToolbar>
			<FilterButton />
			{props.contractId && (
				<CreateButton
					onClick={(e) => {
						e.preventDefault();
						history.push(
							`/${Permissions.contractFee.feature}/create?contractId=${props.contractId}`
						);
					}}
				/>
			)}
			{!props.contractId && <ExportButton />}
		</TopToolbar>
	);
};

export const ContractFeeList = (props) => {
	const filters = [
		<NumberInput label="ID" source="id||eq" />,
		<BooleanInput
			label="Non scaduti"
			source="endDate||isnull"
			defaultValue={true}
			onPasteCapture={() => true}
			disabled
		/>,
		<BooleanInput
			label="Scaduti"
			source="endDate||notnull"
			defaultValue={true}
			onPasteCapture={() => true}
			disabled
		/>,
		<ReferenceInput
			filterToQuery={(searchText) => {
				if (!searchText) return {};
				return {
					name: searchText,
				};
			}}
			label="Fee Type"
			source="feeTypeId||eq"
			reference={Permissions.feeType.feature}
		>
			<AutocompleteInput optionValue="id" />
		</ReferenceInput>,
		<ReferenceInput
			filterToQuery={(searchText) => {
				if (!searchText) return {};
				return {
					name: searchText,
				};
			}}
			label="Corporate Customer"
			source="contract.corporateCustomerId||eq"
			reference={Permissions.corporateCustomers.feature}
		>
			<AutocompleteInput optionValue="id" />
		</ReferenceInput>,
		<ReferenceInput
			alwaysOn
			filterToQuery={(searchText) => {
				if (!searchText) return {};

				return {
					name: searchText,
				};
			}}
			label="Contratto"
			source="contractId||eq"
			reference={Permissions.contract.feature}
			disabled={props.contractId !== undefined}
		>
			<AutocompleteInput optionValue="id"/>
		</ReferenceInput>,
		<DateInput source={`startDate||gte`} label="Inizio da" />,
		<DateInput source={`endDate||lte`} label="Fine al" />,
		<BooleanInput
			label="Solo Attivi"
			source="active"
			defaultValue={true}
		/>,
	];
	return (
		<>
			{props.contractId && (
				<Typography
					variant="h5"
					color="primary"
					style={{ padding: "10px" }}
				>
					Fee
				</Typography>
			)}
			<List
				{...props}
				basePath={`/${Permissions.contractFee.feature}`}
				resource={`${Permissions.contractFee.feature}`}
				actions={<ListActions contractId={props.contractId} />}
				filters={filters}
				filterDefaultValues={
					props.contractId
						? {
								"contractId||eq": parseInt(props.contractId),
						  }
						: {}
				}
				bulkActionButtons={false}
			>
				<Datagrid rowClick="show">
					<TextField label="ID" source="id" />
					{!props.contractId && (
						<ReferenceField
							label="Contratto"
							source="contractId"
							link="show"
							reference={Permissions.contract.feature}
						>
							<TextField source="name" />
						</ReferenceField>
					)}
					<ReferenceField
						label="Punto di Vendita"
						source="sellingPointId"
						link="show"
						reference={Permissions.sellingPoint.feature}
					>
						<TextField source="name" />
					</ReferenceField>
					<DateField label="Data di inizio fee" source="startDate" />
					<DateField label="Data di fine" source="endDate" />
					<NumberField
						label="Importo"
						source="amount"
						options={{ style: "currency", currency: "EUR" }}
					/>
				</Datagrid>
			</List>
		</>
	);
};
