import React from "react";
import {
	Edit,
	SimpleForm,
	ReferenceInput,
	AutocompleteInput,
	NumberInput,
	SelectInput,
	DateInput,
} from "react-admin";
import BaseEdit from "../../inheritance/BaseEdit";
import { Permissions } from "../../../permissions/permissions";
import { required } from "react-admin";

export const ContractFeeEdit = (props) => {
	return (
		<Edit {...props}>
			<SimpleForm redirect={"show"}>
				<ReferenceInput
					fullWidth
					validate={[required("campo obbligatorio")]}
					filterToQuery={(searchText) => {
						if (!searchText) return {};
						return {
							name: searchText,
						};
					}}
					label="Contratto"
					source="contractId"
					reference={Permissions.contract.feature}
					disabled
				>
					<AutocompleteInput optionText="name" optionValue="id" />
				</ReferenceInput>
				<FilteredReferenceInput
					label="Selling Point"
					source="sellingPointId"
					reference={Permissions.sellingPoint.feature}
					filterValue="contract.corporateCustomer.b2bClientId"
					filterId="place.b2bClientId"
					optionText="name"
					optionValue="id"
					disabled
				/>
				<FilteredReferenceInput
					label="Famiglia PO"
					source="corporateCustomerPOFamilyId"
					reference={Permissions.corporateCustomerPOFamily.feature}
					filterValue="contract.corporateCustomerId"
					filterId="corporateCustomerId"
					optionText="name"
					optionValue="id"
					allowEmpty
				/>
				<ReferenceInput
					fullWidth
					validate={[required("campo obbligatorio")]}
					filterToQuery={(searchText) => {
						if (!searchText) return {};
						return {
							name: searchText,
						};
					}}
					label="Tipologia di servizio"
					source="feeTypeId"
					reference={Permissions.feeType.feature}
				>
					<AutocompleteInput optionText="name" optionValue="id" />
				</ReferenceInput>
				<NumberInput
					fullWidth
					label="Importo"
					source="amount"
					validate={[required("campo obbligatorio")]}
				/>
				<DateInput
					fullWidth
					label="Data di inizio fee"
					source="startDate"
					validate={[required("campo obbligatorio")]}
				/>
				<DateInput
					fullWidth
					label="Data di fine"
					source="endDate"
					validate={[required("campo obbligatorio")]}
				/>
				<DateInput
					fullWidth
					label="Data di Inizio fatturazione"
					source="billingStartDate"
					validate={[required("campo obbligatorio")]}
				/>
				<DateInput
					fullWidth
					label="Data di fine periodo minimo"
					source="earlyTerminationAllowedDate"
				/>
				<SelectInput
					fullWidth
					label="Strategia P&L"
					source="pnlStrategy"
					validate={[required("campo obbligatorio")]}
					choices={[
						{
							id: "Included",
							name: "Includi",
						},
						{
							id: "Excluded",
							name: "Escludi",
						},
					]}
				/>
				<NumberInput
					fullWidth
					label="Visite Minime"
					source="minVisits"
					min={0}
					validate={[required("campo obbligatorio")]}
				/>
				<NumberInput
					fullWidth
					label="Visite Massime"
					source="maxVisits"
					min={0}
					validate={[required("campo obbligatorio")]}
				/>
				<BaseEdit />
			</SimpleForm>
		</Edit>
	);
};

const FilteredReferenceInput = (props) => {
	if (!props.record) return null;

	const filters = props.filterValue.split(".");
	let record = props.record;
	for (const key of filters) {
		if (!record[key]) {
			return null;
		}
		record = record[key];
	}

	return (
		<ReferenceInput
			fullWidth
			validate={props.validate}
			disabled={props.disabled}
			filterToQuery={props.filterToQuery}
			label={props.label}
			source={props.source}
			reference={props.reference}
			allowEmpty={props.allowEmpty}
			filter={
				record
					? {
							[props.filterId]: record,
					  }
					: {}
			}
		>
			<AutocompleteInput
				optionText={props.optionText}
				optionValue={props.optionValue}
			/>
		</ReferenceInput>
	);
};
