import React from "react";
import {
	Edit,
	SimpleForm,
	TextInput,
	DateInput,
	ReferenceInput,
	AutocompleteInput,
} from "react-admin";
import BaseEdit from "../../inheritance/BaseEdit";
import { required } from "react-admin";
import { Permissions } from "../../../permissions/permissions";


export const CorporateCustomerPOEdit = (props) => (
	<Edit {...props}>
		<SimpleForm redirect="show">
			<ReferenceInput
					fullWidth
					validate={[required("campo obbligatorio")]}
					filterToQuery={(searchText) => {
						if (!searchText) return {};
						return {
							name: searchText,
						};
					}}
					label="Famiglia di PO"
					source="corporateCustomerPOFamilyId"
					reference={Permissions.corporateCustomerPOFamily.feature}
				>
					<AutocompleteInput
						optionText="name"
						optionValue="id"
						disabled
					/>
				</ReferenceInput>
			<TextInput fullWidth label="PO Number" source="poNumber" />
			<DateInput
				fullWidth
				label="Inizio"
				source="startDate"
				validate={[required("campo obbligatorio")]}
			/>
			<DateInput fullWidth label="Fine" source="endDate" />
			<TextInput fullWidth label="Commento" source="comment" />
			<TextInput fullWidth label="File Name" source="fileName" />
			<BaseEdit />
		</SimpleForm>
	</Edit>
);
