import React from "react";
import { Tooltip, Typography, Box } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";

export function TooltipField(props) {
	if (
		!props.record ||
		props.record[props.source] === null ||
		props.record[props.source] === undefined
	) {
		return null;
	}

	return (
		<Box width={"fit-content"} style={{ marginBottom: "7px" }}>
			<Box
				display="flex"
				alignItems="center"
				style={{ gap: "15px", marginBottom: "7px" }}
			>
				<Typography variant="caption" style={{ color: "#0000008a" }}>
					{props.label}
				</Typography>
				<Tooltip
					title={
						<Typography
							variant="caption"
							dangerouslySetInnerHTML={{
								__html: props.tooltip,
							}}
						/>
					}
					style={{ fontSize: "large" }}
				>
					<HelpIcon
						fontSize="small"
						style={{
							transform: "scale(0.8)",
							cursor: "pointer",
						}}
					/>
				</Tooltip>
			</Box>
			<Typography variant="p">{props.record[props.source]}</Typography>
		</Box>
	);
}
