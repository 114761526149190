import React, { useEffect, useState } from 'react';
import { Create, SimpleForm, TextInput, AutocompleteInput, FormDataConsumer, useDataProvider, Loading, ReferenceInput, AutocompleteArrayInput, ArrayInput, SimpleFormIterator, DateTimeInput, ReferenceArrayInput, BooleanInput, NumberInput, minValue } from 'react-admin';
import { required } from 'react-admin';
import { useHistory } from "react-router-dom";
import { Permissions, CondionActions } from '../../../permissions/permissions';
import NewInputSelectRest, { AutocompleteInputType } from '../../../components/InputRest';
import { useForm } from 'react-final-form';
import { POST_OFF_RESOURCE } from '../../../providers/nestjs_crud';
import { Typography } from '@material-ui/core';

const fieldOptions = `${Permissions.condition.feature}/${CondionActions.fieldOptions}`;
const BOOLEAN_FACTS = ['firstOrder', 'hasCorporateCustomer']

export function ConditionCreate(props) {
	let history = useHistory();
    const params = new URLSearchParams(props.location.search);
    const parentId = params.get('parentId');

	return (
		<Create {...props}>
			<SimpleForm redirect={() => {
				history.goBack();
				return '';
        	}}>
				<div style={{display: 'none'}}>
                	<TextInput initialValue={parentId} source="promotionId"  validate={[required('campo obbligatorio')]} />
            	</div>
				<FormDataConsumer>
					{(props) => {
						return (
							<CustomForm
								{...props}
							/>
						);
					}}
        		</FormDataConsumer>
			</SimpleForm>
		</Create>
	);
}

const STATUS_FETCH_DATA = "fetch_data";
const STATUS_FETCHING_DATA = "fetching_data";
const STATUS_DATA_FETCHED = "data_fetched";
const STATUS_FETCHING_DATA_ERROR = "fetching_data_error";

export function CustomForm(props) {
	const form = useForm();
	const [status, setStatus] = useState(STATUS_FETCH_DATA);
	const [choices, setDataChoices] = useState([]);
	const dataProvider = useDataProvider();

	useEffect(() => {
        if(status === STATUS_FETCH_DATA) {
			setStatus(STATUS_FETCHING_DATA);
			dataProvider(POST_OFF_RESOURCE, `${Permissions.condition.feature}/${CondionActions.fieldOptions}/factType`, {
				data: form.getState().values
			})
			.then(({data}) => {
				setDataChoices(data.choices);
				setStatus(STATUS_DATA_FETCHED);
			})
			.catch((e) => {
				setStatus(STATUS_FETCHING_DATA_ERROR)
			});
		}
	}, [dataProvider, form, status]);

	if(status === STATUS_FETCHING_DATA_ERROR) {
		return <Typography>Errore durante il caricamento</Typography>
	}

	if(status === STATUS_DATA_FETCHED) {
		return (
			<>
				<AutocompleteInput	
					onChange={(value) => {
						form.change('operator', undefined);
						if(!BOOLEAN_FACTS.includes(props.formData.factType)) {
							form.change('value', undefined);
						}
					}}
					fullWidth
					emptyText="Non specificato"
					label="FactType"
					source="factType"
					validate={[required('campo obbligatorio')]}
					choices={choices}
				/>
				<NewInputSelectRest
					onChange={(value) => {
						if(!BOOLEAN_FACTS.includes(props.formData.factType)) {
							form.change('value', undefined);
						}
					}}
					type={AutocompleteInputType}
					fieldOptions={fieldOptions}
					label="Operator"
					source="operator"
					_dependsOn={["factType"]}
					validate={[required('campo obbligatorio')]} />
				{props.formData.factType && props.formData.operator && <Value
					formData={props.formData}
					data={choices}
				/>}
			</>
		);
	}

	return <Loading />;
}

export function Value(props) {
	const factTypeComplete = props.data.find(dataItem => {
		return dataItem.id === props.formData.factType;
	});

	if(!factTypeComplete) {
		return null;
	}

	if(
		[
			'itemId',
			'sellingPointId',
			'clientCategoryId'
		].includes(factTypeComplete.id)
	) {
		const config = {
			itemId: {
				feature: Permissions.item.feature,
				searchText: 'name',
			},
			sellingPointId: {
				feature: Permissions.sellingPoint.feature,
				searchText: 'name',
			},
			clientCategoryId: {
				feature: Permissions.clientCategory.feature,
				searchText: 'internalName',
			},
		}
		switch (props.formData.operator) {
			case 'in':
			case 'notIn':
				return (
					<ReferenceArrayInput
						fullWidth
						multiple
						allowNull
						filterToQuery={searchText => {
							if(!searchText)
								return {};
		
							return { [config[factTypeComplete.id].searchText]: searchText }
						}}
						label={factTypeComplete.name}
						source='value'
						reference={config[factTypeComplete.id].feature}
						validate={[required('campo obbligatorio')]}
					>
					<AutocompleteArrayInput
						optionText={(record)=> {
							if(!record) {
								return '';
							}
							return `${record.id} ${record[config[factTypeComplete.id].searchText]}`
						}}
						optionValue="id" />
				</ReferenceArrayInput>
			);
			default:
				return (
					<ReferenceInput
						fullWidth
						allowNull
						filterToQuery={searchText => {
							if(!searchText)
								return {};
		
							return { [config[factTypeComplete.id].searchText]: searchText }
						}}
						label={factTypeComplete.name}
						source='value'
						reference={config[factTypeComplete.id].feature}
						validate={[required('campo obbligatorio')]}
					>
						<AutocompleteInput
							optionText={(record)=> {
								if(!record) {
									return '';
								}
								return `${record.id} ${record[config[factTypeComplete.id].searchText]}`
							}}
							optionValue="id" />
				</ReferenceInput>
			);
			
		}
	}

	if(BOOLEAN_FACTS.includes(factTypeComplete.id)){
		return (
			<BooleanInput disabled={true} label={factTypeComplete.name} source="value" defaultValue={true} validate={[required('campo obbligatorio')]}/>
		)
	}

	if (props.formData.operator === 'in' || props.formData.operator === 'notIn') {
		return (
			<ArrayInput label={factTypeComplete.name} source="value">
			<SimpleFormIterator>
				{'timestampSinceEpoch' === factTypeComplete.id ? <DateTimeInput /> : <TextInput />}
			</SimpleFormIterator>
		</ArrayInput>)
	}

	if('timestampSinceEpoch' === factTypeComplete.id) {
		return <DateTimeInput fullWidth label={factTypeComplete.name} source="value"/>	
	}

	if(factTypeComplete.id === 'daysSinceLastOrder') {
		return <NumberInput fullWidth label={factTypeComplete.name} source="value" validate={[required('campo obbligatorio'), minValue(0, 'Il valore deve essere maggiore o uguale a 0')]}/>
	}

	return <TextInput fullWidth label={factTypeComplete.name} source="value"/>
}