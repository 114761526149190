import React from "react";
import {
	Create,
	SimpleForm,
	TextInput,
	ReferenceInput,
	AutocompleteInput,
	SelectInput,
} from "react-admin";
import BaseCreate from "../../inheritance/BaseCreate";
import { required } from "react-admin";
import { Permissions } from "../../../permissions/permissions";

export const serviceTypeChoices = [
	{ id: "Fee", name: "Fee" },
	{ id: "Catering", name: "Catering" },
	{ id: "Credito", name: "Credito" },
	{ id: "Caffe", name: "Caffe" },
	{ id: "Frutta", name: "Frutta" },
	{ id: "Consegne dirette", name: "Consegne dirette" },
	{ id: "Catering continuativo", name: "Catering continuativo" },
	{ id: "Multipli", name: "Multipli" },
];

export const CorporateCustomerPOFamilyCreate = (props) => (
	<Create {...props}>
		<SimpleForm redirect="list">
			<ReferenceInput
				validate={[required("campo obbligatorio")]}
				filterToQuery={(searchText) => {
					if (!searchText) return {};
					return {
						name: searchText,
					};
				}}
				label="Corporate Customer"
				source="corporateCustomerId"
				reference={Permissions.corporateCustomers.feature}
			>
				<AutocompleteInput optionText="name" optionValue="id" />
			</ReferenceInput>
			<TextInput label="Name" source="name" helperText="Se vuoto viene calcolato automaticamente"/>
			<SelectInput
				label="Servizio"
				source="serviceType"
				choices={serviceTypeChoices}
				validate={[required("campo obbligatorio")]}
			/>
			<TextInput label="Sotto Categoria" source="subCategory" />
			<TextInput label="Link" source="link" />
			<BaseCreate />
		</SimpleForm>
	</Create>
);
