import React from "react";
import {
	Datagrid,
	List,
	TextField,
	NumberInput,
	ReferenceField,
	ReferenceInput,
	AutocompleteInput,
	NumberField,
	DateField,
	DateInput,
	BooleanInput,
	TopToolbar,
	FilterButton,
	CreateButton,
	ExportButton,
	AutocompleteArrayInput,
} from "react-admin";
import { Permissions } from "../../permissions/permissions";
import { Box, Tooltip, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import WarningIcon from "@material-ui/icons/Warning";
import { monthDistance } from "../../utils/DateTime/Date";
import CloseIcon from "@material-ui/icons/Close";

const status = [
    { id: 'DRAFT', name: 'DRAFT' },
    { id: 'COMPLETED', name: 'COMPLETED' },
    { id:'ACTIVE', name: 'ACTIVE' },
];

const ListActions = (props) => {
	const history = useHistory();

	return (
		<TopToolbar>
			<FilterButton />
			{!props.corporateCustomerId && <CreateButton />}
			{props.corporateCustomerId && (
				<CreateButton
					onClick={(e) => {
						e.preventDefault();
						history.push(
							`/${Permissions.contract.feature}/create?corporateCustomerId=${props.corporateCustomerId}`
						);
					}}
				/>
			)}
			{!props.corporateCustomerId && <ExportButton />}
		</TopToolbar>
	);
};

export const ContractList = (props) => {
	const filters = [
		<NumberInput label="ID" source="id||eq" />,
		<ReferenceInput
			alwaysOn
			filterToQuery={(searchText) => {
				if (!searchText) return {};

				return {
					name: searchText,
				};
			}}
			label="Corporate Customer"
			source="corporateCustomerId||eq"
			reference={Permissions.corporateCustomers.feature}
			disabled={props.corporateCustomerId !== undefined}
		>
			<AutocompleteInput optionValue="id" />
		</ReferenceInput>,
		<AutocompleteArrayInput
			label="STATO"
			source="contractStateMachine.status||in"
			choices={status}
			translateChoice={false}
			allowEmpty={false}
		/>,
		<ReferenceInput
			filterToQuery={(searchText) => {
				if (!searchText) return {};

				return {
					name: searchText,
				};
			}}
			label="Gruppo di fatturazione"
			source="billingGroupId||eq"
			reference={Permissions.billingGroup.feature}
		>
			<AutocompleteInput optionValue="id" />
		</ReferenceInput>,
		<BooleanInput
			label="Senza data di chiusura"
			source="endDate||isnull"
			defaultValue={true}
			onPasteCapture={() => true}
			disabled
		/>,
		<BooleanInput
			label="Con data di chiusura"
			source="endDate||notnull"
			defaultValue={true}
			onPasteCapture={() => true}
			disabled
		/>,
		<DateInput source={`startDate||gte`} label="Inizio da" />,
		<DateInput source={`endDate||lte`} label="Fine al" />,
		<DateInput
			source={`preRenewalEndDate||gte`}
			label="Fine pre rinnovo dal"
		/>,
		<DateInput
			source={`preRenewalEndDate||lte`}
			label="Fine pre rinnovo al"
		/>,
		<DateInput
			source={`postRenewalEndDate||gte`}
			label="Fine post rinnovo dal"
		/>,
		<DateInput
			source={`postRenewalEndDate||lte`}
			label="Fine post rinnovo al"
		/>,
		<BooleanInput
			label="Solo Attivi"
			source="active"
			defaultValue={true}
		/>,
	];

	return (
		<>
			{props.corporateCustomerId && (
				<Typography
					variant="h5"
					color="primary"
					style={{ padding: "10px" }}
				>
					Contratti
				</Typography>
			)}
			<List
				{...props}
				basePath={`/${Permissions.contract.feature}`}
				resource={`${Permissions.contract.feature}`}
				actions={
					<ListActions
						corporateCustomerId={props.corporateCustomerId}
					/>
				}
				filters={filters}
				filterDefaultValues={
					props.corporateCustomerId
						? {
								"corporateCustomerId||eq":
									props.corporateCustomerId,
						  }
						: {}
				}
			>
				<Datagrid rowClick="show">
					<TextField label="ID" source="id" />
					<TextField label="Nome" source="name" />
					<ReferenceField
						label="Corporate Customer"
						source="corporateCustomerId"
						link="show"
						reference={Permissions.corporateCustomers.feature}
					>
						<TextField source="name" />
					</ReferenceField>
					<NumberField
						label="Valore"
						source="value"
						options={{ style: "currency", currency: "EUR" }}
					/>
					<DateField label="Data di inizio contratto" source="startDate" />
					<DateField
						label="Data di chiusura contratto"
						source="endDate"
					/>
					<ContractNote label="Controlli" />
				</Datagrid>
			</List>
		</>
	);
};

function ContractNote(props) {
	if (!props.record) {
		return <></>;
	}

	const { record } = props;
	const MONTHS_ALERT = 3;
	let alarm = false;
	const endDate = record.endDate ? new Date(record.endDate) : null;
	const preRenewalEndDate = new Date(record.preRenewalEndDate);
	const postRenewalEndDate = new Date(record.postRenewalEndDate);
	const today = new Date();

	if (endDate && endDate < today) {
		return (
			<Box display="flex" justifyContent="center">
				<Tooltip
					title={
						<span style={{ fontSize: "0.9rem" }}>
							Contratto chiuso
						</span>
					}
				>
					<CloseIcon style={{ color: "#086D40" }} />
				</Tooltip>
			</Box>
		);
	}

	const messages = [];

	const endDateDistance = endDate ? monthDistance(today, endDate) : 1000;
	const preRenewalEndDateDistance = monthDistance(today, preRenewalEndDate);
	const postRenewalEndDateDistance = monthDistance(today, postRenewalEndDate);

	if (endDateDistance >= 0 && endDateDistance <= MONTHS_ALERT) {
		let msg =
			endDateDistance > 0 ? `< ${endDateDistance} mesi` : "questo mese";
		messages.push(`&bull; Data di chiusura contratto (${msg})</br>`);
	}

	if (
		preRenewalEndDateDistance >= 0 &&
		preRenewalEndDateDistance <= MONTHS_ALERT
	) {
		let msg =
			preRenewalEndDateDistance > 0
				? `< ${preRenewalEndDateDistance} mesi`
				: "questo mese";
		messages.push(`&bull; Data di scadenza pre rinnovo (${msg})</br>`);
	}

	if (
		postRenewalEndDateDistance >= 0 &&
		postRenewalEndDateDistance <= MONTHS_ALERT
	) {
		let msg =
			postRenewalEndDateDistance > 0
				? `< ${postRenewalEndDateDistance} mesi`
				: "questo mese";
		messages.push(`&bull; Data di scadenza post rinnovo (${msg})</br>`);
	}

	if (postRenewalEndDateDistance < 0 && !endDate) {
		alarm = true;
		let msg = `${Math.abs(postRenewalEndDateDistance)} mesi fa`;
		messages.push(
			`<strong>&bull; Contratto scaduto ma non chiuso (${msg})</strong></br>`
		);
	}

	if (messages.length === 0) {
		return <></>;
	}

	return (
		<Box display="flex" justifyContent="center">
			<Tooltip
				title={
					<span
						style={{ fontSize: "0.9rem" }}
						dangerouslySetInnerHTML={{
							__html: messages.join(""),
						}}
					/>
				}
			>
				<WarningIcon style={{ color: alarm ? "red" : "#ffc700" }} />
			</Tooltip>
		</Box>
	);
}
