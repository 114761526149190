import React from "react";
import {
	Show,
	SimpleShowLayout,
	TextField,
	ReferenceField,
	NumberField,
	DateField,
} from "react-admin";
import { Permissions } from "../../permissions/permissions";
import { Grid, Container } from "@material-ui/core";
import { ContractFeeList } from "./fee/ContractFeeList";
import StateMachineReferenceField from "../../components/StateMachineReferenceField";
import { StateMachineFieldWithActions } from "../../components/StateMachineFieldWithActions";
import BaseAsideWithUser from "../inheritance/BaseAsideWithUser";
import { TooltipField } from "../../components/TooltipField";

export const ContractShow = (props) => {
	return (
		<Container maxWidth="xl">
			<Grid container spacing={3}>
				<Grid item xl={12} lg={12} md={12} xs={12}>
					<Show
						aside={
							<BaseAsideWithUser
								props={props}
								record={props.record}
								hasHistory
							></BaseAsideWithUser>
						}
						{...props}
					>
						<SimpleShowLayout>
							<TextField label="ID" source="id" />
							<TextField label="Nome" source="name" />
							<ReferenceField
								label="Corporate Customer"
								source="corporateCustomerId"
								link="show"
								reference={
									Permissions.corporateCustomers.feature
								}
							>
								<TextField source="name" />
							</ReferenceField>
							<ReferenceField
								label="Gruppo di fatturazione"
								source="billingGroupId"
								link="show"
								reference={Permissions.billingGroup.feature}
							>
								<TextField source="name" />
							</ReferenceField>
							<TooltipField
								label="Valore (€)"
								source="value"
								tooltip="SOMMA(valore fee x durata fee) <strong>/</strong> durata contratto fino a data post rinnovo"
							/>

							<NumberField
								label="Frequenza ( mesi )"
								source="billingFrequency"
							/>
							<TextField
								label="Giorni di pagamento ( ggfm )"
								source="paymentNetDays"
							/>
							<DateField
								label="Data di firma"
								source="signedAt"
							/>
							<DateField
								label="Data di inizio contratto"
								source="startDate"
							/>
							<DateField
								label="Data di chiusura contratto"
								source="endDate"
							/>
							<DateField
								label="Data di fine pre rinnovo"
								source="preRenewalEndDate"
							/>
							<DateField
								label="Data di fine post rinnovo"
								source="postRenewalEndDate"
							/>
							<DateField
								label="Data di fine periodo di prova"
								source="endTrialDate"
							/>
							<DateField
								label="Data di ricezione PEC disdetta"
								source="cancellationNoticedDate"
							/>
							<TextField
								label="Note interne"
								source="internalComment"
							/>
							<TextField
								label="Note per cliente ( visibili in fatturazione )"
								source="externalComment"
							/>
							<TextField
								label="Metodo di pagamento"
								source="paymentProvider"
							/>
							<TextField
								label="Informazioni di pagamento"
								source="paymentProviderData"
							/>
							<StateMachineReferenceField
								label="Stato"
								source="contractStateMachine"
								reference={`${Permissions.contract.feature}_${Permissions.contract.subFeatures.contractStateMachine.name}`}
							></StateMachineReferenceField>
							<StateMachineFieldWithActions
								label="Transizioni"
								reference={`${Permissions.contract.feature}_${Permissions.contract.subFeatures.contractStateMachine.name}`}
								source="contractStateMachineId"
							/>
						</SimpleShowLayout>
					</Show>
				</Grid>
				<Grid container spacing={3}>
					<Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
						<ContractFeeList contractId={props.id} />
					</Grid>
				</Grid>
			</Grid>
		</Container>
	);
};
