import React from 'react';
import { SimpleShowLayout, TextField, TopToolbar, EditButton, showNotification, useDataProvider, NumberField, usePermissions, ShowController, ShowView, ArrayField, SingleFieldList, useRefresh } from 'react-admin';
import Aside from '../inheritance/BaseAside';
import Button from '@material-ui/core/Button';
import KitchenIcon from '@material-ui/icons/Kitchen';
import { GET_OFF_RESOURCE_PLAIN, GET_OFF_RESOURCE } from '../../providers/nestjs_crud';
import { useDispatch } from 'react-redux';
import { Permissions } from '../../permissions/permissions';
import PublishIcon from '@material-ui/icons/Publish';
import { Typography } from '@material-ui/core';
import UpdateIcon from '@material-ui/icons/Update';


const ShowActions = (props) => {
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();
    const { permissions } = usePermissions();
    const refresh = useRefresh();

	return (
		<TopToolbar>
			<EditButton basePath={props.basePath} record={props.data} />
            {props.data && props.data.id && permissions && permissions.includes(`${Permissions.lock.feature}-${Permissions.lock.actions.open}`) &&
                <Button
                    onClick={() => {
                        dataProvider(GET_OFF_RESOURCE_PLAIN, `${props.resource}/${props.data.id}/open`)
                        .then(message => {
                            dispatch(showNotification(message));
                        })
                        .catch(message => {
                            dispatch(showNotification(message, 'error'));
                        });
                    }}
                    color="primary"><KitchenIcon></KitchenIcon> Apri</Button>
            }
            {props.data && props.data.id && permissions && permissions.includes(`${Permissions.lock.feature}-${Permissions.lock.actions.forceShadowUpdate}`) &&
                <Button
                onClick={() => {
                    dataProvider(GET_OFF_RESOURCE, `${Permissions.lock.feature}/${props.data.id}/force-shadow-update`)
                    .then(data => {
                        if(data.data.success === false) {
                            dispatch(showNotification(data.data.message, 'error'));
                        }  else {
                            refresh();
                            dispatch(showNotification("Shadow Aggiornata"));
                        }
                    })
                    .catch(error => {
                        dispatch(showNotification(error.message, 'error'));
                    });
                }}
                color="primary"><PublishIcon></PublishIcon> Aggiorna Shadow</Button>
            }
		</TopToolbar>
	);
}

export const LockShow = (props) => {
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();
    const { permissions } = usePermissions();
    const refresh = useRefresh();

    return (
        <ShowController {...props}>
        {controllerProps =>
            <ShowView {...props} {...controllerProps} aside={<Aside props={props} record={props.record}></Aside>} actions={<ShowActions />} >
                <SimpleShowLayout>
                    <TextField label="ID" source="id" />
                    <TextField label="Nome" source="name" />
                    <TextField label="ThingName" source="thingName" />
                    <TextField source="assetType" label="Asset Type" />
                    <TextField source="assetIdentifier" label="Asset Identifier"/>
                    <TextField source="temperatureTrackingState" label="Temperature Tracking State" />
                    <TextField source="temperatureTrackingMacAddress" label="Temperature Tracking Mac Address" />
                    <NumberField source="temperatureTarget" label="Temperature Target" />i
                    <NumberField source="temperatureOffset" label="Temperature Offset" />
                    <NumberField source="version" label="Version" />
                    <TextField source="chipModel" label="Chip Model" />
                    {controllerProps.record && controllerProps.record.shadow && <Typography key="title" variant="body1" gutterBottom> Shadow</Typography>}
                    {controllerProps.record && controllerProps.record.shadow && controllerProps.record.shadow.desired && [
                        <Typography key="title" variant="body2" gutterBottom>Desired</Typography>,
                        <NumberField key="shadow.desired.version" source="shadow.desired.version" label="version" />,
                        <ArrayField label="sensors" key="shadow.desired.temperatureSensors" source="shadow.desired.temperatureSensors">
                            <SingleFieldList linkType={false}>
                                <ul>
                                    <li>
                                        <small>type</small> : <TextField source="assetType"/>
                                    </li>
                                    <li>
                                        <small>id</small> : <TextField source="assetIdentifier"/>
                                    </li>
                                    <li>
                                        <small>traking state</small> : <TextField source="trackingState"/>
                                    </li>
                                    <li>
                                        <small>mac</small> : <TextField source="trackingMacAddress"/>
                                    </li>
                                    <li>
                                        <small>temp. target</small> : <NumberField source="target"/>
                                    </li>
                                    <li>
                                        <small>offset</small> : <NumberField source="offset"/>
                                    </li>
                                </ul>
                            </SingleFieldList>
                        </ArrayField>
                    ]}
                    {controllerProps.record && controllerProps.record.shadow && controllerProps.record.shadow.reported && [
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}><Typography variant="body2" gutterBottom>Reported</Typography>
                        {permissions && permissions.includes(`${Permissions.lock.feature}-${Permissions.lock.actions.forceShadowReport}`) &&
                            <Button
                                onClick={() => {
                                    dataProvider(GET_OFF_RESOURCE, `${Permissions.lock.feature}/${controllerProps.record.id}/force-shadow-report`)
                                    .then(data => {
                                        if(data.data.success === false) {
                                            dispatch(showNotification(data.data.message, 'error'));
                                        }  else {
                                            setTimeout(() => {
                                                refresh();
                                                dispatch(showNotification("Reported Aggiornato"));
                                            }, 5000);
                                        }
                                    })
                                    .catch(error => {
                                        dispatch(showNotification(error.message, 'error'));
                                    });
                                }}
                                color="primary">Force report<UpdateIcon /></Button>
                            }
                        </div>,
                        <NumberField key="shadow.reported.version" source="shadow.reported.version" label="version" />,
                        <ArrayField label="sensors" key="shadow.reported.temperatureSensors" source="shadow.reported.temperatureSensors">
                            <SingleFieldList linkType={false}>
                                <ul>
                                    <li>
                                        <small>type</small> : <TextField source="assetType"/>
                                    </li>
                                    <li>
                                        <small>id</small> : <TextField source="assetIdentifier"/>
                                    </li>
                                    <li>
                                        <small>traking state</small> : <TextField source="trackingState"/>
                                    </li>
                                    <li>
                                        <small>mac</small> : <TextField source="trackingMacAddress"/>
                                    </li>
                                    <li>
                                        <small>Temp. target</small> : <NumberField source="target"/>
                                    </li>
                                    <li>
                                        <small>offset</small> : <NumberField source="offset"/>
                                    </li>
                                </ul>
                            </SingleFieldList>
                        </ArrayField>,
                        <TextField key="shadow.reported.connectedApMacAddress" source="shadow.reported.connectedApMacAddress" label="WIFI MAC Address" />,
                        <ArrayField label="telemetry" key="shadow.reported.telemtry.temperatureSensors" source="shadow.reported.telemetry.temperatureSensors">
                            <SingleFieldList linkType={false}>
                                <ul>
                                    <li>
                                        <small>type</small> : <TextField source="assetType"/>
                                    </li>
                                    <li>
                                        <small>id</small> : <TextField source="assetIdentifier"/>
                                    </li>
                                    <li>
                                        <small>temperature</small> : <TextField source="temperature"/>
                                    </li>
                                    <li>
                                        <small>battery</small> : <TextField source="batteryInMv"/>
                                    </li>
                                    <li>
                                        <small>RSSI</small> : <NumberField source="RSSI"/>
                                    </li>
                                    <li>
                                        <small>humidity</small> : <NumberField source="humidity"/>
                                    </li>
                                </ul>
                            </SingleFieldList>
                        </ArrayField>,
                    ]}
                </SimpleShowLayout>
            </ShowView>}
        </ShowController>
    )
};
