import React from "react";
import {
	Datagrid,
	List,
	TextField,
	NumberInput,
	ReferenceField,
	ReferenceInput,
	AutocompleteInput,
} from "react-admin";
import Filter from "../inheritance/BaseFilter";
import ListActions from "../../components/ListActions";
import { Permissions } from "../../permissions/permissions";

const BillingGroupFilter = (props) => (
	<Filter {...props}>
		<NumberInput label="ID" source="id||eq" alwaysOn />
		<ReferenceInput
			alwaysOn
			filterToQuery={(searchText) => {
				if (!searchText) return {};

				return {
					"name": searchText,
				};
			}}
			label="Corporate Customer"
			source="corporateCustomerId||eq"
			reference={Permissions.corporateCustomers.feature}
		>
			<AutocompleteInput optionValue="id" />
		</ReferenceInput>
	</Filter>
);

export const BillingGroupList = (props) => (
	<List {...props} actions={<ListActions />} filters={<BillingGroupFilter />}>
		<Datagrid rowClick="show">
			<TextField label="ID" source="id" />
			<TextField label="Name" source="name" />
			<ReferenceField
				label="Corporate Customer"
				source="corporateCustomerId"
				link="show"
				reference={Permissions.corporateCustomers.feature}
			>
				<TextField source="name" />
			</ReferenceField>
			<TextField source="comment" />
		</Datagrid>
	</List>
);
