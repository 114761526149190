import React from 'react';
import { Admin, Resource, Layout } from 'react-admin';
import crudProvider from './providers/nestjs_crud';

import internalCategory from "./resources/internalCategory";
import item from "./resources/item";
import tax from "./resources/tax";
import clientCategory from "./resources/clientCategory";
import unitOfMeasurement from "./resources/unitOfMeasurement";
import itemSaleable from "./resources/itemSaleable";
import place from "./resources/place";
import sellingPoint from "./resources/sellingPoint";
import priceList from "./resources/priceList";
import itemSaleablePriceList from "./resources/itemSaleablePriceList";
import sellingPointImpulse from "./resources/sellingPointImpulse";
import user from "./resources/user";
import SignIn from "./sign-in/SignIn";
import authProvider from './sign-in/authProvider';
import role from './resources/role';
import Dashboard from './Dashboard';
import sellingOrder from "./resources/sellingOrder";
import sellingOrderRow from "./resources/sellingOrderRow";
import sellingOrderImpulse from "./resources/transaction/sellingOrderImpulse";
import { Permissions } from './permissions/permissions';
import { createTheme } from '@material-ui/core/styles';
import itemStorable from './resources/item/itemStorable';
import itemPurchasable from './resources/item/itemPurchasable';
import itemEdible from './resources/item/itemEdible';
import supplier from './resources/purchase/supplier';
import supplierPriceList from './resources/purchase/supplierPriceList';
import Menu from './components/Menu';
import stateMachineHistory from './resources/stateMachineHistory';
import stateMachine from './resources/stateMachine';
import purchasePoint from './resources/purchase/purchasePoint';
import purchaseOrder from './resources/purchase/purchaseOrder';
import purchaseOrderRow from './resources/purchase/purchaseOrder/purchaseOrderRow';
import lock from './resources/lock';
import code from './resources/code';
import locker from './resources/locker';
import B2CUser from './resources/B2CUser';
import LockerSellingOrder from "./resources/transaction/lockerSellingOrder";
import Payment from "./resources/transaction/payment";
import PaymentAttempt from "./resources/transaction/payment/paymentAttempt";
import Lot from "./resources/warehouse/lot";
import WarehouseLocation from "./resources/warehouse/location";
import Transfer from "./resources/warehouse/transfer";
import RequestedItemTransfer_Sub from "./resources/warehouse/transfer/requestedItemTransfer";
import RequestedItemTransfer from "./resources/warehouse/transfer/requestedItemTransfer/RequestedItemTransfer";
import PhysicalItemTransfer from "./resources/warehouse/transfer/physicalItemTransfer";
import PhysicalItemTransfer_Sub from "./resources/warehouse/transfer/physicalItemTransfer/physicalItemTransfer_Sub";
import Domains from "./resources/domains";
import CorporateCustomers from "./resources/corporateCustomers";
import Files from "./resources/files";
import accr_WarehouseItems from "./resources/warehouse/accr_warehouseItems";
import WalletAccountType from "./resources/wallet/acc_account/accountType";
import WalletAccAccount from "./resources/wallet/acc_account";
import gateway from './resources/gateway';
import walletManualTransfer from './resources/transaction/walletManualTransfer';
import walletTransfer from './resources/transaction/walletTransfer';
import walletRechargeConfig from './resources/transaction/walletRecharge/walletRechargeConfig';
import customRoutes from './customRoutes';
import Promotion from './resources/promotion';
import Condition from './resources/promotion/condition';
import WarehouseAccAccount from './resources/warehouse/accounting/account';
import Hub from './resources/warehouse/hub';
import Mission from './resources/transaction/mission';
import Mission_Rider from './resources/transaction/mission/rider';
import MissionV2 from './resources/ops/mission';
import CorporateCredit from './resources/corporateCredit';
import CorporateCreditCondition from './resources/corporateCredit/condition';
import CorporateCreditHistory from './resources/corporateCredit/history';
import CoffeeRecipe from './resources/coffee/recipe';
import CoffeeMachine from './resources/coffee/machine';
import CoffeeType from './resources/coffee/type';
import CoffeeMachineCimbaliS from './resources/coffee/integrations/cimbali-s-series/machine';
import CoffeeSellingPoint from './resources/coffee/sellingPoint';
import CoffeeRecipeList from './resources/coffee/recipeList';
import CoffeeSellingOrder from "./resources/transaction/coffeeSellingOrder";
import WalletRecharge from './resources/wallet/recharge';
import CoffeeRecipeListItem from './resources/coffee/recipeList/recipeListItem';
import BusinessUnit from './resources/businessUnit';
import MainUser from './resources/mainUser';
import SolubleType from './resources/coffee/solubleType';
import TransferPicking from "./resources/warehouse/transfer/transferPicking";
import TimeSlotRule from "./resources/timeSlotRule";
import AppBarWithHubSelection from './AppBarWithHubSelection';
import deliveryMan from './resources/deliveryMan';
import forecastCategory from './resources/forecastCategory';
import clientCluster from './resources/clientCluster';
import B2BClient from './resources/B2BClient';
import opsStateMachineShow  from './resources/ops/stateMachine/';
import opsStateMachineHistory from './resources/ops/stateMachineHistory';
import layout from './resources/layout';
import layoutPhoto from './resources/layout/layoutPhoto';
import deliveryManSkill from './resources/deliveryManSkill'
import warehouseInventory from './resources/transaction/warehouseInventory';
import warehouseInventoryItem from './resources/transaction/warehouseInventory/warehouseInventoryItem';
import deliveryManMission from './resources/ops/deliveryMan/mission';
import deliveryManMissionJob from './resources/ops/deliveryMan/missonJob';
import missionJob from "./resources/ops/mission/job";
import jobTask from "./resources/ops/mission/job/task";
import taskConfig from "./resources/ops/mission/job/task/taskConfig";
import missionJobTransferAssignment from "./resources/ops/mission/job/transferAssignment";
import RecurringMission from "./resources/ops/mission/recurring/mission";
import RecurringMissionExecution from "./resources/ops/mission/recurring/execution";
import RecurringMissionJob from "./resources/ops/mission/recurring/mission/job";
import MissionJobTags from "./resources/ops/mission/job/tags";
import FeeType from "./resources/feeType";
import BillingGroup from "./resources/billingGroup";
import CorporateCustomerPOFamily from "./resources/CorporateCustomerPO/Family";
import CorporateCustomerPO from "./resources/CorporateCustomerPO/Individual";
import Contract from "./resources/contract";
import ContractFee from "./resources/contract/fee";
import History from "./resources/history";

import {
  GET_LIST,
  GET_ONE
} from 'react-admin';

const dataProvider = crudProvider(process.env.REACT_APP_WAREHOUSE_API, {
  [Permissions.sellingPoint.feature]: {
    [GET_LIST]: {
      joins: [Permissions.place.feature,]
    },
  },
  [Permissions.item.feature]: { 
    [GET_ONE]: {
      joins: [
        Permissions.itemEdible.feature, 
        Permissions.itemPurchasable.feature, 
        Permissions.itemSaleable.feature, 
        Permissions.itemStorable.feature,
        'category',
        Permissions.forecastCategory.feature,
      ]
    },
  },
  [Permissions.layout.feature]: {
    [GET_ONE]: {
      joins: ['layoutPhotos'] 
    },
  },
  [Permissions.deliveryMan.feature]: {
    [GET_ONE]: {
      joins: ['skills'] 
    },
    [GET_LIST]: {
      joins: ['skills'] 
    },
  },
  [Permissions.warehouseInventory.feature]: {
    [GET_ONE]: {
      joins: ['warehouseInventoryStateMachine', 'location'] 
    },
  },
  [Permissions.requestedItemTransfer.feature]: { 
    [GET_ONE]: {
      joins: [
        'physicalItemTransfers', 
        Permissions.item.feature,
        `${Permissions.item.feature}.unitOfMeasurements`
      ]
    },
    [GET_LIST]: {
      joins: [
        'physicalItemTransfers',
        Permissions.item.feature,
        `${Permissions.item.feature}.unitOfMeasurements`
      ]
    },
  },
  [Permissions.missionJob.feature]: {
    [GET_LIST]: {
      joins: ['stateMachine'] 
    },
    [GET_ONE]: {
      joins: [
        'stateMachine',
        'mission',
        'mission.stateMachine'
      ] 
    },
  },
  [Permissions.jobTask.feature]: {
    [GET_LIST]: {
      joins: ['stateMachine'] 
    },
    [GET_ONE]: {
      joins: ['stateMachine'] 
    },
  },
  [Permissions.missionJobTransferAssignment.feature]: {
    [GET_LIST]: {
      joins: [
        'stateMachine',
        'mission',
        'mission.stateMachine'
      ]
    },
  },
  [Permissions.corporateCredit.feature]: {
    [GET_LIST]: {
      joins: ['corporateCustomer'] 
    },
  },
  [Permissions.place.feature]: {
    [GET_ONE]: {
      joins: ['skills'] 
    },
  },
  [Permissions.taskConfig.feature]: {
    [GET_ONE]: {
      joins: ['jobTag'] 
    },
    [GET_LIST]: {
      joins: ['jobTag'] 
    },
  },
  [Permissions.contract.feature]: {
    [GET_LIST]: {
      joins: ['corporateCustomer'] 
    },
  },
  [Permissions.contractFee.feature]: {
    [GET_LIST]: {
      joins: ['contract'] 
    },
    [GET_ONE]: {
      joins: ['contract', 'contract.corporateCustomer'] 
    },
  },
});

const getResources = (resource, componnents, permissions, subFeature = null) => {
    return {
      edit: componnents.edit && permissions.includes(`${resource}-${Permissions[resource].actions.UpdateOne}`) ? componnents.edit : null,
      list: componnents.list && permissions.includes(`${resource}-${Permissions[resource].actions.ReadAll}`) ? componnents.list : null,
      create: componnents.create && permissions.includes(`${resource}-${Permissions[resource].actions.CreateOne}`) ? componnents.create : null,
      show: componnents.show && permissions.includes(`${resource}-${Permissions[resource].actions.ReadOne}`) ? componnents.show : null
    }
}

const theme = createTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_WAREHOUSE_API.includes('staging') ? '#ff9800' : '#086d40'
    },
    secondary: {
      main: process.env.REACT_APP_WAREHOUSE_API.includes('staging') ? '#ff9800' : '#086d40'
    }
  },
  sidebar: {
    closedWidth: 0,
},
});

const App = () => {
  return (
  <Admin
    layout={(props) => <Layout {...props} appBar={AppBarWithHubSelection} />}
    theme={theme}
    dashboard={Dashboard}
    loginPage={SignIn}
    authProvider={authProvider}
    customRoutes={customRoutes}
    dataProvider={dataProvider}
    menu={Menu}>
    {
      permissions => ([
        <Resource key={Permissions.item.feature} name={Permissions.item.feature} {...getResources(Permissions.item.feature, item, permissions)}/>,
        <Resource key={Permissions.unitOfMeasurement.feature} name={Permissions.unitOfMeasurement.feature} {...getResources(Permissions.unitOfMeasurement.feature, unitOfMeasurement, permissions)}/>,
        <Resource key={Permissions.internalCategory.feature} name={Permissions.internalCategory.feature} {...getResources(Permissions.internalCategory.feature, internalCategory, permissions)}/>,
        <Resource key={Permissions.tax.feature} name={Permissions.tax.feature} {...getResources(Permissions.tax.feature, tax, permissions)}/>,
        <Resource key={Permissions.clientCategory.feature} name={Permissions.clientCategory.feature} {...getResources(Permissions.clientCategory.feature, clientCategory, permissions)}/>,
        <Resource key={Permissions.itemSaleable.feature} name={Permissions.itemSaleable.feature}  {...getResources(Permissions.itemSaleable.feature, itemSaleable, permissions)}/>,
        <Resource key={Permissions.place.feature} name={Permissions.place.feature} {...getResources(Permissions.place.feature, place, permissions)}/>,
        <Resource key={Permissions.sellingPoint.feature} name={Permissions.sellingPoint.feature} {...getResources(Permissions.sellingPoint.feature, sellingPoint, permissions)}/>,
        <Resource key={Permissions.priceList.feature} name={Permissions.priceList.feature} {...getResources(Permissions.priceList.feature, priceList, permissions)}/>,
        <Resource key={Permissions.itemSaleablePriceList.feature} name={Permissions.itemSaleablePriceList.feature} {...getResources(Permissions.itemSaleablePriceList.feature, itemSaleablePriceList, permissions)}/>,
        <Resource key={Permissions.sellingPointImpulse.feature} name={Permissions.sellingPointImpulse.feature} {...getResources(Permissions.sellingPointImpulse.feature, sellingPointImpulse, permissions)}/>,
        <Resource key={Permissions.user.feature} name={Permissions.user.feature} {...getResources(Permissions.user.feature, user, permissions)}/>,
        <Resource key={Permissions.role.feature} name={Permissions.role.feature} {...getResources(Permissions.role.feature, role, permissions)}/>,
        <Resource key={Permissions.sellingOrder.feature} name={Permissions.sellingOrder.feature} {...getResources(Permissions.sellingOrder.feature, sellingOrder, permissions)}/>,
        <Resource key={Permissions.sellingOrderRow.feature} name={Permissions.sellingOrderRow.feature} {...getResources(Permissions.sellingOrderRow.feature, sellingOrderRow, permissions)}/>,
        <Resource key={Permissions.sellingOrderImpulse.feature} name={Permissions.sellingOrderImpulse.feature} {...getResources(Permissions.sellingOrderImpulse.feature, sellingOrderImpulse, permissions)}/>,
        <Resource key={Permissions.itemStorable.feature} name={Permissions.itemStorable.feature} {...getResources(Permissions.itemStorable.feature, itemStorable, permissions)}/>,
        <Resource key={Permissions.itemPurchasable.feature} name={Permissions.itemPurchasable.feature} {...getResources(Permissions.itemPurchasable.feature, itemPurchasable, permissions)}/>,
        <Resource key={Permissions.itemEdible.feature} name={Permissions.itemEdible.feature} {...getResources(Permissions.itemEdible.feature, itemEdible, permissions)}/>,
        <Resource key={Permissions.supplier.feature} name={Permissions.supplier.feature} {...getResources(Permissions.supplier.feature, supplier, permissions)}/>,
        <Resource key={Permissions.supplierPriceList.feature} name={Permissions.supplierPriceList.feature} {...getResources(Permissions.supplierPriceList.feature, supplierPriceList, permissions)}/>,
        <Resource key={Permissions.stateMachineHistory.feature} name={Permissions.stateMachineHistory.feature} {...getResources(Permissions.stateMachineHistory.feature, stateMachineHistory, permissions)}/>,
        <Resource key={Permissions.opsStateMachineHistory.feature} name={Permissions.opsStateMachineHistory.feature} {...getResources(Permissions.opsStateMachineHistory.feature, opsStateMachineHistory, permissions)}/>,
        <Resource key={`${Permissions.sellingOrderImpulse.feature}_${Permissions.sellingOrderImpulse.subFeatures.orderStateMachine.name}`} name={`${Permissions.sellingOrderImpulse.feature}_${Permissions.sellingOrderImpulse.subFeatures.orderStateMachine.name}`} {...getResources(Permissions.sellingOrderImpulse.feature, stateMachine, permissions, Permissions.sellingOrderImpulse.subFeatures.orderStateMachine.name)}/>,
        <Resource key={`${Permissions.sellingOrder.feature}_${Permissions.sellingOrder.subFeatures.orderStateMachine.name}`} name={`${Permissions.sellingOrder.feature}_${Permissions.sellingOrder.subFeatures.orderStateMachine.name}`} {...getResources(Permissions.sellingOrder.feature, stateMachine, permissions, Permissions.sellingOrder.subFeatures.orderStateMachine.name)}/>,
        <Resource key={Permissions.purchasePoint.feature} name={Permissions.purchasePoint.feature} {...getResources(Permissions.purchasePoint.feature, purchasePoint, permissions)}/>,
        <Resource key={Permissions.purchaseOrder.feature} name={Permissions.purchaseOrder.feature} {...getResources(Permissions.purchaseOrder.feature, purchaseOrder, permissions)}/>,
        <Resource key={`${Permissions.purchaseOrder.feature}_${Permissions.purchaseOrder.subFeatures.orderStateMachine.name}`} name={`${Permissions.purchaseOrder.feature}_${Permissions.purchaseOrder.subFeatures.orderStateMachine.name}`} {...getResources(Permissions.purchaseOrder.feature, stateMachine, permissions, Permissions.purchaseOrder.subFeatures.orderStateMachine.name)}/>,
        <Resource key={Permissions.purchaseOrderRow.feature} name={Permissions.purchaseOrderRow.feature} {...getResources(Permissions.purchaseOrderRow.feature, purchaseOrderRow, permissions)}/>,
        <Resource key={Permissions.lock.feature} name={Permissions.lock.feature} {...getResources(Permissions.lock.feature, lock, permissions)}/>,
        <Resource key={Permissions.code.feature} name={Permissions.code.feature} {...getResources(Permissions.code.feature, code, permissions)}/>,
        <Resource key={Permissions.locker.feature} name={Permissions.locker.feature} {...getResources(Permissions.locker.feature, locker, permissions)}/>,
        <Resource key={Permissions.B2CUser.feature} name={Permissions.B2CUser.feature} {...getResources(Permissions.B2CUser.feature, B2CUser, permissions)}/>,
        <Resource key={Permissions.lockerSellingOrder.feature} name={Permissions.lockerSellingOrder.feature} {...getResources(Permissions.lockerSellingOrder.feature, LockerSellingOrder, permissions)}/>,
        <Resource key={`${Permissions.lockerSellingOrder.feature}_${Permissions.lockerSellingOrder.subFeatures.orderStateMachine.name}`} name={`${Permissions.lockerSellingOrder.feature}_${Permissions.lockerSellingOrder.subFeatures.orderStateMachine.name}`} {...getResources(Permissions.lockerSellingOrder.feature, stateMachine, permissions, Permissions.lockerSellingOrder.subFeatures.orderStateMachine.name)}/>,
        <Resource key={Permissions.payment.feature} name={Permissions.payment.feature} {...getResources(Permissions.payment.feature, Payment, permissions)}/>,
        <Resource key={`${Permissions.payment.feature}_${Permissions.payment.subFeatures.paymentStateMachine.name}`} name={`${Permissions.payment.feature}_${Permissions.payment.subFeatures.paymentStateMachine.name}`} {...getResources(Permissions.payment.feature, stateMachine, permissions, Permissions.payment.subFeatures.paymentStateMachine.name)}/>,
        <Resource key={Permissions.paymentAttempt.feature} name={Permissions.paymentAttempt.feature} {...getResources(Permissions.paymentAttempt.feature, PaymentAttempt, permissions)}/>,
        <Resource key={`${Permissions.paymentAttempt.feature}_${Permissions.paymentAttempt.subFeatures.paymentAttemptStateMachine.name}`} name={`${Permissions.paymentAttempt.feature}_${Permissions.paymentAttempt.subFeatures.paymentAttemptStateMachine.name}`} {...getResources(Permissions.paymentAttempt.feature, stateMachine, permissions, Permissions.paymentAttempt.subFeatures.paymentAttemptStateMachine.name)}/>,
        <Resource key={Permissions.lot.feature} name={Permissions.lot.feature} {...getResources(Permissions.lot.feature, Lot, permissions)}/>,
        <Resource key={Permissions.warehouseLocation.feature} name={Permissions.warehouseLocation.feature} {...getResources(Permissions.warehouseLocation.feature, WarehouseLocation, permissions)}/>,
        <Resource key={Permissions.transfer.feature} name={Permissions.transfer.feature} {...getResources(Permissions.transfer.feature, Transfer, permissions)}/>,
        <Resource key={`${Permissions.transfer.feature}_${Permissions.transfer.subFeatures.transferStateMachine.name}`} name={`${Permissions.transfer.feature}_${Permissions.transfer.subFeatures.transferStateMachine.name}`} {...getResources(Permissions.transfer.feature, stateMachine, permissions, Permissions.transfer.subFeatures.transferStateMachine.name)}/>,
        <Resource key={`${Permissions.transfer.feature}_${Permissions.transfer.subFeatures.requestedItemTransfer.name}`} name={`${Permissions.transfer.feature}_${Permissions.transfer.subFeatures.requestedItemTransfer.name}`} {...getResources(Permissions.transfer.feature, RequestedItemTransfer_Sub, permissions, Permissions.transfer.subFeatures.requestedItemTransfer.name)}/>,
        <Resource key={Permissions.requestedItemTransfer.feature} name={Permissions.requestedItemTransfer.feature} {...getResources(Permissions.requestedItemTransfer.feature, RequestedItemTransfer, permissions)}/>,
        <Resource key={Permissions.physicalItemTransfer.feature} name={Permissions.physicalItemTransfer.feature} {...getResources(Permissions.physicalItemTransfer.feature, PhysicalItemTransfer, permissions)}/>,
        <Resource key={`${Permissions.transfer.feature}_${Permissions.transfer.subFeatures.physicalItemTransfer.name}`} name={`${Permissions.transfer.feature}_${Permissions.transfer.subFeatures.physicalItemTransfer.name}`} {...getResources(Permissions.transfer.feature, PhysicalItemTransfer_Sub, permissions, Permissions.transfer.subFeatures.physicalItemTransfer.name)}/>,
        <Resource key={Permissions.domains.feature} name={Permissions.domains.feature} {...getResources(Permissions.domains.feature, Domains, permissions)}/>,
        <Resource key={Permissions.corporateCustomers.feature} name={Permissions.corporateCustomers.feature} {...getResources(Permissions.corporateCustomers.feature, CorporateCustomers, permissions)}/>,
        <Resource key={Permissions.files.feature} name={Permissions.files.feature} {...getResources(Permissions.files.feature, Files, permissions)}/>,
        <Resource key={Permissions.accrWarehouseItems.feature} name={Permissions.accrWarehouseItems.feature} {...getResources(Permissions.accrWarehouseItems.feature, accr_WarehouseItems, permissions)}/>,
        <Resource key={Permissions.walletAccAccountType.feature} name={Permissions.walletAccAccountType.feature} {...getResources(Permissions.walletAccAccountType.feature, WalletAccountType, permissions)}/>,
        <Resource key={Permissions.walletAccAccount.feature} name={Permissions.walletAccAccount.feature} {...getResources(Permissions.walletAccAccount.feature, WalletAccAccount, permissions)}/>,
        <Resource key={Permissions.gateway.feature} name={Permissions.gateway.feature} {...getResources(Permissions.gateway.feature, gateway, permissions)}/>,
        <Resource key={Permissions.walletManualTransfer.feature} name={Permissions.walletManualTransfer.feature} {...getResources(Permissions.walletManualTransfer.feature, walletManualTransfer, permissions)}/>,
        <Resource key={Permissions.walletTransfer.feature} name={Permissions.walletTransfer.feature} {...getResources(Permissions.walletTransfer.feature, walletTransfer, permissions)}/>,
        <Resource key={Permissions.walletRechargeConfig.feature} name={Permissions.walletRechargeConfig.feature} {...getResources(Permissions.walletRechargeConfig.feature, walletRechargeConfig, permissions)}/>,
        <Resource key={Permissions.walletRecharge.feature} name={Permissions.walletRecharge.feature} {...getResources(Permissions.walletRecharge.feature, WalletRecharge, permissions)} />,
        <Resource key={`${Permissions.walletRecharge.feature}_${Permissions.walletRecharge.subFeatures.walletRechargeStateMachine.name}`} name={`${Permissions.walletRecharge.feature}_${Permissions.walletRecharge.subFeatures.walletRechargeStateMachine.name}`} {...stateMachine} />,
        <Resource key={Permissions.promotion.feature} name={Permissions.promotion.feature} {...getResources(Permissions.promotion.feature, Promotion, permissions)}/>,
        <Resource key={Permissions.condition.feature} name={Permissions.condition.feature} {...getResources(Permissions.condition.feature, Condition, permissions)}/>,
        <Resource key={Permissions.warehouseAccAccount.feature} name={Permissions.warehouseAccAccount.feature} {...getResources(Permissions.warehouseAccAccount.feature, WarehouseAccAccount, permissions)}/>,
        <Resource key={Permissions.hub.feature} name={Permissions.hub.feature} {...getResources(Permissions.hub.feature, Hub, permissions)}/>,
        <Resource key={Permissions.mission.feature} name={Permissions.mission.feature} {...getResources(Permissions.mission.feature, Mission, permissions)}/>,
        <Resource key={`${Permissions.mission.feature}_${Permissions.mission.subFeatures.rider.name}`} name={`${Permissions.mission.feature}_${Permissions.mission.subFeatures.rider.name}`} {...getResources(Permissions.mission.feature, Mission_Rider, permissions, Permissions.mission.subFeatures.rider.name)}/>,  
        <Resource key={Permissions.missionV2.feature} name={Permissions.missionV2.feature} {...getResources(Permissions.missionV2.feature, MissionV2, permissions)}/>,
        <Resource key={`${Permissions.missionV2.feature}_${Permissions.missionV2.subFeatures.stateMachine.name}`} name={`${Permissions.missionV2.feature}_${Permissions.missionV2.subFeatures.stateMachine.name}`} {...getResources(Permissions.missionV2.feature, opsStateMachineShow, permissions, Permissions.missionV2.subFeatures.stateMachine.name)}/>,
        <Resource key={Permissions.corporateCredit.feature} name={Permissions.corporateCredit.feature} {...getResources(Permissions.corporateCredit.feature, CorporateCredit, permissions)}/>,
        <Resource key={Permissions.corporateCreditCondition.feature} name={Permissions.corporateCreditCondition.feature} {...getResources(Permissions.corporateCreditCondition.feature, CorporateCreditCondition, permissions)}/>,
        <Resource key={Permissions.corporateCreditHistory.feature} name={Permissions.corporateCreditHistory.feature} {...getResources(Permissions.corporateCreditHistory.feature, CorporateCreditHistory, permissions)}/>,
        <Resource key={Permissions.coffeeRecipe.feature} name={Permissions.coffeeRecipe.feature} {...getResources(Permissions.coffeeRecipe.feature, CoffeeRecipe, permissions)}/>,
        <Resource key={Permissions.coffeeMachine.feature} name={Permissions.coffeeMachine.feature} {...getResources(Permissions.coffeeMachine.feature, CoffeeMachine, permissions)}/>,
        <Resource key={Permissions.coffeeType.feature} name={Permissions.coffeeType.feature} {...getResources(Permissions.coffeeType.feature, CoffeeType, permissions)}/>,
        <Resource key={Permissions.coffeeMachineCimbaliS.feature} name={Permissions.coffeeMachineCimbaliS.feature} {...getResources(Permissions.coffeeMachineCimbaliS.feature, CoffeeMachineCimbaliS, permissions)}/>,
        <Resource key={Permissions.coffeeRecipeList.feature} name={Permissions.coffeeRecipeList.feature} {...getResources(Permissions.coffeeRecipeList.feature, CoffeeRecipeList, permissions)}/>,
        <Resource key={Permissions.coffeeSellingPoint.feature} name={Permissions.coffeeSellingPoint.feature} {...getResources(Permissions.coffeeSellingPoint.feature, CoffeeSellingPoint, permissions)}/>,
        <Resource key={Permissions.coffeeSellingOrder.feature} name={Permissions.coffeeSellingOrder.feature} {...getResources(Permissions.coffeeSellingOrder.feature, CoffeeSellingOrder, permissions)}/>,
        <Resource key={`${Permissions.coffeeSellingOrder.feature}_${Permissions.coffeeSellingOrder.subFeatures.orderStateMachine.name}`} name={`${Permissions.coffeeSellingOrder.feature}_${Permissions.coffeeSellingOrder.subFeatures.orderStateMachine.name}`} {...getResources(Permissions.coffeeSellingOrder.feature, stateMachine, permissions, Permissions.coffeeSellingOrder.subFeatures.orderStateMachine.name)}/>,
        <Resource key={Permissions.coffeeRecipeListItem.feature} name={Permissions.coffeeRecipeListItem.feature} {...getResources(Permissions.coffeeRecipeListItem.feature, CoffeeRecipeListItem, permissions)}/>,
        <Resource key={Permissions.businessUnit.feature} name={Permissions.businessUnit.feature} {...getResources(Permissions.businessUnit.feature, BusinessUnit, permissions)}/>,
        <Resource key={Permissions.mainUser.feature} name={Permissions.mainUser.feature} {...getResources(Permissions.mainUser.feature, MainUser, permissions)}/>,
        <Resource key={Permissions.solubleType.feature} name={Permissions.solubleType.feature} {...getResources(Permissions.solubleType.feature, SolubleType, permissions)}/>,
        <Resource key={Permissions.transferPicking.feature} name={Permissions.transferPicking.feature} {...getResources(Permissions.transferPicking.feature, TransferPicking, permissions)}/>,
        <Resource key={Permissions.timeSlotRule.feature} name={Permissions.timeSlotRule.feature} {...getResources(Permissions.timeSlotRule.feature, TimeSlotRule, permissions)}/>,
        <Resource key={Permissions.deliveryMan.feature} name={Permissions.deliveryMan.feature} {...getResources(Permissions.deliveryMan.feature, deliveryMan, permissions)}/>,
        <Resource key={Permissions.forecastCategory.feature} name={Permissions.forecastCategory.feature} {...getResources(Permissions.forecastCategory.feature, forecastCategory, permissions)}/>,
        <Resource key={Permissions.clientCluster.feature} name={Permissions.clientCluster.feature} {...getResources(Permissions.clientCluster.feature, clientCluster, permissions)}/>,
        <Resource key={Permissions.B2BClient.feature} name={Permissions.B2BClient.feature} {...getResources(Permissions.B2BClient.feature, B2BClient, permissions)}/>,
        <Resource key={Permissions.layout.feature} name={Permissions.layout.feature} {...getResources(Permissions.layout.feature, layout, permissions)}/>,
        <Resource key={Permissions.layoutPhoto.feature} name={Permissions.layoutPhoto.feature} {...getResources(Permissions.layoutPhoto.feature, layoutPhoto, permissions)}/>,
        <Resource key={Permissions.deliveryManSkill.feature} name={Permissions.deliveryManSkill.feature} {...getResources(Permissions.deliveryManSkill.feature, deliveryManSkill, permissions)}/>,
        <Resource key={Permissions.warehouseInventory.feature} name={Permissions.warehouseInventory.feature} {...getResources(Permissions.warehouseInventory.feature, warehouseInventory, permissions)}/>,
        <Resource key={`${Permissions.warehouseInventory.feature}_${Permissions.warehouseInventory.subFeatures.warehouseInventoryStateMachine.name}`} name={`${Permissions.warehouseInventory.feature}_${Permissions.warehouseInventory.subFeatures.warehouseInventoryStateMachine.name}`} {...getResources(Permissions.warehouseInventory.feature, stateMachine, permissions, Permissions.warehouseInventory.subFeatures.warehouseInventoryStateMachine.name)}/>,
        <Resource key={Permissions.warehouseInventoryItem.feature} name={Permissions.warehouseInventoryItem.feature} {...getResources(Permissions.warehouseInventoryItem.feature, warehouseInventoryItem, permissions)}/>,
        <Resource key={Permissions.deliveryManMission.feature} name={Permissions.deliveryManMission.feature} {...getResources(Permissions.deliveryManMission.feature, deliveryManMission, permissions)}/>,
        <Resource key={Permissions.deliveryManMissionJob.feature} name={Permissions.deliveryManMissionJob.feature} {...getResources(Permissions.deliveryManMissionJob.feature, deliveryManMissionJob, permissions)}/>,
        <Resource key={Permissions.missionJob.feature} name={Permissions.missionJob.feature} {...getResources(Permissions.missionJob.feature, missionJob, permissions)}/>,
        <Resource key={`${Permissions.missionJob.feature}_${Permissions.missionJob.subFeatures.stateMachine.name}`} name={`${Permissions.missionJob.feature}_${Permissions.missionJob.subFeatures.stateMachine.name}`} {...getResources(Permissions.missionJob.feature, opsStateMachineShow, permissions, Permissions.missionJob.subFeatures.stateMachine.name)}/>,
        <Resource key={Permissions.jobTask.feature} name={Permissions.jobTask.feature} {...getResources(Permissions.jobTask.feature, jobTask, permissions)}/>,
        <Resource key={`${Permissions.jobTask.feature}_${Permissions.jobTask.subFeatures.stateMachine.name}`} name={`${Permissions.jobTask.feature}_${Permissions.jobTask.subFeatures.stateMachine.name}`} {...getResources(Permissions.jobTask.feature, opsStateMachineShow, permissions, Permissions.jobTask.subFeatures.stateMachine.name)}/>,
        <Resource key={Permissions.taskConfig.feature} name={Permissions.taskConfig.feature} {...getResources(Permissions.taskConfig.feature, taskConfig, permissions)}/>,
        <Resource key={Permissions.outboundLocation.feature} name={Permissions.outboundLocation.feature} {...getResources(Permissions.outboundLocation.feature, {
        }, permissions)}/>,
        <Resource key={Permissions.missionJobTransferAssignment.feature} name={Permissions.missionJobTransferAssignment.feature} {...getResources(Permissions.missionJobTransferAssignment.feature, missionJobTransferAssignment, permissions)}/>,
        <Resource key={Permissions.recurringMission.feature} name={Permissions.recurringMission.feature} {...getResources(Permissions.recurringMission.feature, RecurringMission, permissions)}/>,
        <Resource key={`${Permissions.recurringMission.feature}_${Permissions.recurringMission.subFeatures.stateMachine.name}`} name={`${Permissions.recurringMission.feature}_${Permissions.recurringMission.subFeatures.stateMachine.name}`} {...getResources(Permissions.recurringMission.feature, opsStateMachineShow, permissions, Permissions.recurringMission.subFeatures.stateMachine.name)}/>,
        <Resource key={Permissions.recurringMissionJob.feature} name={Permissions.recurringMissionJob.feature} {...getResources(Permissions.recurringMissionJob.feature, RecurringMissionJob, permissions)}/>,
        <Resource key={Permissions.recurringMissionExecution.feature} name={Permissions.recurringMissionExecution.feature} {...getResources(Permissions.recurringMissionExecution.feature, RecurringMissionExecution, permissions)}/>,
        <Resource key={Permissions.missionJobTag.feature} name={Permissions.missionJobTag.feature} {...getResources(Permissions.missionJobTag.feature, MissionJobTags, permissions)}/>,
        <Resource key={Permissions.feeType.feature} name={Permissions.feeType.feature} {...getResources(Permissions.feeType.feature, FeeType, permissions)}/>,
        <Resource key={Permissions.billingGroup.feature} name={Permissions.billingGroup.feature} {...getResources(Permissions.billingGroup.feature, BillingGroup, permissions)}/>,
        <Resource key={Permissions.corporateCustomerPOFamily.feature} name={Permissions.corporateCustomerPOFamily.feature} {...getResources(Permissions.corporateCustomerPOFamily.feature, CorporateCustomerPOFamily, permissions)}/>,
        <Resource key={Permissions.corporateCustomerPO.feature} name={Permissions.corporateCustomerPO.feature} {...getResources(Permissions.corporateCustomerPO.feature, CorporateCustomerPO, permissions)}/>,
        <Resource key={Permissions.contract.feature} name={Permissions.contract.feature} {...getResources(Permissions.contract.feature, Contract, permissions)}/>,
        <Resource key={Permissions.contractFee.feature} name={Permissions.contractFee.feature} {...getResources(Permissions.contractFee.feature, ContractFee, permissions)}/>,
        <Resource key={`${Permissions.contract.feature}_${Permissions.contract.subFeatures.contractStateMachine.name}`} name={`${Permissions.contract.feature}_${Permissions.contract.subFeatures.contractStateMachine.name}`} {...getResources(Permissions.contract.feature, stateMachine, permissions, Permissions.contract.subFeatures.contractStateMachine.name)}/>,
        <Resource key={Permissions.history.feature} name={Permissions.history.feature} {...getResources(Permissions.history.feature, History, permissions)}/>,
      ])
    }
  </Admin>
)};
export default App;