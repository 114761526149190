import React from "react";
import {
	Edit,
	SimpleForm,
	TextInput,
	ReferenceInput,
	AutocompleteInput,
} from "react-admin";
import BaseEdit from "../inheritance/BaseEdit";
import { Permissions } from "../../permissions/permissions";
import { required } from "react-admin";

export const BillingGroupEdit = (props) => (
	<Edit {...props}>
		<SimpleForm>
			<ReferenceInput
				validate={[required("campo obbligatorio")]}
				filterToQuery={(searchText) => {
					if (!searchText) return {};
					return {
						name: searchText,
					};
				}}
				label="Corporate Customer"
				source="corporateCustomerId"
				reference={Permissions.corporateCustomers.feature}
			>
				<AutocompleteInput disabled optionText="name" optionValue="id" />
			</ReferenceInput>
			<TextInput label="Name" source="name" validate={[required("campo obbligatorio")]}/>
			<TextInput label="Commento" source="comment" />
			<TextInput label="Codice" source="code" />
			<BaseEdit />
		</SimpleForm>
	</Edit>
);
