import React from "react";
import {
	Datagrid,
	List,
	TextField,
	TextInput,
	ReferenceField,
	ReferenceInput,
	AutocompleteInput,
} from "react-admin";
import Filter from "../inheritance/BaseFilter";
import ListActions from "../../components/ListActions";
import { Permissions } from "../../permissions/permissions";

const FeeTypeFilter = (props) => (
	<Filter {...props}>
		<TextInput label="Nome" source="name" alwaysOn />
		<ReferenceInput
			alwaysOn
			filterToQuery={(searchText) => {
				if (!searchText) return {};

				return {
					name: searchText,
				};
			}}
			label="Business Unit"
			source="businessUnitId||eq"
			reference={Permissions.businessUnit.feature}
		>
			<AutocompleteInput optionValue="id" />
		</ReferenceInput>
	</Filter>
);

export const FeeTypeList = (props) => (
	<List {...props} actions={<ListActions />} filters={<FeeTypeFilter />}>
		<Datagrid rowClick="show">
			<TextField label="ID" source="id" />
			<TextField label="Nome" source="name" />
			<ReferenceField
				label="Business Unit"
				source="businessUnitId"
				link="show"
				reference={Permissions.businessUnit.feature}
			>
				<TextField source="name" />
			</ReferenceField>
			<ReferenceField
				label="Tassa"
				source="taxId"
				link="show"
				reference={Permissions.tax.feature}
			>
				<TextField source="name" />
			</ReferenceField>
		</Datagrid>
	</List>
);
